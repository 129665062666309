const CatalogOverviewBook = () => import("@/views/catalog/overview-book.vue");
const CatalogOverviewSubscription = () => import("@/views/catalog/overview-subscription.vue");
const CatalogOverviewAll = () => import("@/views/catalog/overview-all.vue");
const CatalogDetails = () => import("@/views/catalog/details.vue");

export default [
    {
        path: "/catalog/subscriptions",
        name: "subscriptions_overview",
        component: CatalogOverviewSubscription,
        meta: { title: 'Order a subscription' },
        props: true
    },
    {
        path: "/catalog/books",
        name: "books_overview",
        component: CatalogOverviewBook,
        meta: { title: 'Order a book' },
        props: true
    },
    {
        path: "/catalog/all",
        name: "products_overview",
        component: CatalogOverviewAll,
        meta: { title: 'Product management' },
        props: true
    },
    {
        path: "/product/details/:id",
        name: "product_details",
        component: CatalogDetails,
        meta: { title: "Product details" },
        props: true,
    },
];
