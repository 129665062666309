import LogView from '@/views/logs/view.vue';

export default [
    {
        path: "/logs",
        name: "logs_view",
        component: LogView,
        props: true,
        meta: { title: "Logs" },
    },
];
