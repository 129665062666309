import forEach from "lodash/forEach";
import store from "./store";
import i18n from "./i18n";

export default function flashMessage (msg: string, type: string = 'success', replace?: object) {
    let opts = {
        color: type
    };

    let translated = i18n.t(msg).toString();
    if (replace) {
        forEach(replace, (val, key) => {
            translated = translated.replace(key, String(val));
        });
    }

    store.commit('flashMessage', { msg: translated, options: opts });
}