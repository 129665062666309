//import { Vue } from 'vue/types/vue';

import flashMessage from "@/flashMessage";

declare module 'vue/types/vue' {
    interface Vue {
        $flashMessage(msg: string, type?: string);
    }
}

export default class FlashMessagesPlugin {
    public static install(Vue, options) {
        Vue.prototype.$flashMessage = flashMessage
    }
}