const JobOverview = () => import("./overview.vue");

export default [
    {
        path: "/job/overview",
        name: "job_overview",
        meta: { title: "Background jobs" },
        component: JobOverview,
        props: true,
    },
];