const ReaderList = () => import("@/views/readerlist/list.vue");

export default [
    {
        path: "/reader/list/",
        name: "reader_list",
        component: ReaderList,
        meta: { title: "Reader list" },
        props: false,
    },
];
