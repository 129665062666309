import PrintQueueList from "./list.vue"

export default [
    {
        path: "/printqueue/list",
        name: "print_queue_list",
        meta: { title: "Send invoices" },
        component: PrintQueueList,
        props: true
    },
];