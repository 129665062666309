import '@/lib/hooks';
import User from "@/entity/user";
import Request from '@/lib/apollo/request';
import checkPasswordResetOnLogin from "@/reset-password-on-login";

declare module 'vue/types/vue' {
    interface Vue {
        credentials: object;

        heartbeatInterval: number;

        $initAuth(res?: object): Promise<void>;

        $login(credentials: any): void;

        $twoFaLogin(credentials: any): void;

        $displayErrorMessage(res: any): void;

        $pushRedirect(): void;

        $loginInit(res: User, pushRedirect?: boolean): void;

        $impersonate(username: string): void;

        $toPrevious(): void;

        $logout(): void;

        $loginReset(): void;

        $heartbeat(): void;

        $checkPasswordResetOnLogin(fromError?: boolean): void;
    }
}

export default class AuthenticationPlugin {
    public static install(Vue, options) {
        Vue.prototype.$credentials = {
            username: '',
            password: '',
            _remember_me: false
        };

        Vue.prototype.$heartbeatInterval = null;

        Vue.prototype.$initAuth = function (res?: object): Promise<void> {
            this.$store.commit('resetAuth');

            let performInit = (result) => {
                if (result) {
                    this.$loginInit(result);
                    this.$heartbeat();
                } else {
                    this.$loginReset();
                    if (this.$router.currentRoute.name !== 'oci_login'
                        && this.$router.currentRoute.name !== 'reset-password'
                        && this.$router.currentRoute.name !== 'public_overview') {
                        this.$router.push({ name: 'login' });
                    }
                }
            };

            if (typeof res === 'undefined') {
                return Request.make('/login', Request.GET).catch((error) => {
                    // Gebruiker is waarschijnlijk niet ingelogd.
                }).then((reqRes: User) => {
                    performInit(reqRes);
                });
            } else {
                performInit(res);
                return new Promise((resolve, eject) => {
                    resolve();
                });
            }
        };

        Vue.prototype.$displayErrorMessage = function (res: any) {
            if (res.msg === 'BadCredentials') {
                this.$flashMessage('LoginFailed', 'warning');
            } else if (res.msg === 'AccountDisabled') {
                this.$flashMessage('LoginFailedAccountDisabled', 'warning');
            } else if (res.msg === 'IPUnauthorized') {
                this.$flashMessage('LoginFailedIPUnauthorized', 'warning');
            } else {
                this.$flashMessage('Error', 'error');
            }
        };

        Vue.prototype.$login = function (credentials: any) {
            Request.make('/login_check', Request.POST, credentials).then((res: User) => {
                if (res.twoFaInProgress) {
                    this.$router.push({name: 'twofa_login'});
                } else {
                    this.$loginInit(res, true);
                }
            }).catch((res: any) => {
                this.$displayErrorMessage(res);
            });
        };

        Vue.prototype.$twoFaLogin = function (credentials: any) {
            const formData = new FormData();
            formData.append('_auth_code', credentials.twoFaCode);

            Request.make('/2fa_check', Request.POST, formData).then((res: any) => {
                if (res.authenticated && !res.twoFaInProgress) {
                    this.$loginInit(res, true);
                }
            }).catch((res) => {
                this.$displayErrorMessage(res);
            });
        }

        Vue.prototype.$pushRedirect = function(): void {
            this.$router.push(this.$store.state.user.settings.defaultPath);
        };

        Vue.prototype.$loginInit = function(res: User, pushRedirect?: boolean): void {
            if (!res.authenticated) {
                this.$store.commit('resetAuth');
                if (this.$router.currentRoute.name !== 'oci_login'
                    && this.$router.currentRoute.name !== 'login'
                    && this.$router.currentRoute.name !== 'reset-password'
                    && this.$router.currentRoute.name !== 'public_overview'
                    && this.$router.currentRoute.name !== 'twofa_login') {
                    this.$router.push({ name: 'login' });
                }
                return;
            }
            pushRedirect = !!pushRedirect;

            this.$store.commit('authenticated', true);
            this.$store.commit('user', res);
            this.$store.commit('organizationName', res.relationName ? res.relationName : '');
            if (this.$i18n) {
                this.$i18n.locale = res.locale;
            }
            this.$store.commit('locale', res.locale);
            this.$root.$emit('change-locale', 'change');

            if (res.passwordResetOnLogin) {
                this.$store.commit('passwordResetOnLogin', true);
                if (this.$router.currentRoute.name !== 'change-password-login') {
                    this.$router.push({ name: 'change-password-login' });
                    return;
                }
            } else {
                this.$store.commit('passwordResetOnLogin', false);
            }

            this.$flashMessage('LoginMessage', 'success');
            this.$heartbeat();

            if (pushRedirect === true) {
                this.$pushRedirect();
            }
        };

        Vue.prototype.$impersonate = function (username: string): void {
            if (!this.$store.state.user.isSwitched) {
                Request.make('/security/impersonate', Request.GET, { poco_switch_user: username }).then((res) => {
                    this.$store.commit('user', res);
                    if (location.hostname === 'admin.postma-cohen.nl') {
                        location.href = 'https://klanten.postma-cohen.nl';
                    } else if (location.hostname === 'staging-admin.postma-cohen.nl') {
                        location.href = 'https://staging-klanten.postma-cohen.nl';
                    } else {
                        this.$flashMessage('LoginImpersonation', 'success', { '%s': username });
                        this.$pushRedirect();
                        this.$router.go();
                    }
                });
            }
        };

        Vue.prototype.$toPrevious = function (): void {
            if (this.$store.state.user.isSwitched) {
                Request.make('/security/impersonate', Request.GET, { poco_switch_user: '_exit' }).then((res) => {
                    this.$store.commit('user', res);
                    this.$flashMessage('U bent nu ingelogd als gebruiker: ' + res.name, 'success');
                    this.$pushRedirect();
                    this.$router.go();
                });
            }
        };

        Vue.prototype.$logout = function (): void {
            Request.make('/logout', Request.GET).then(() => {
                this.$loginReset();
                this.$router.push({ name: 'login' });
            });
        };

        Vue.prototype.$loginReset = function(): void {
            this.$store.commit('authenticated', false);
            this.$store.commit('user', new User());
            this.$store.commit('reset');
            if (this.heartbeatInterval) {
                window.clearInterval(this.heartbeatInterval);
            }
        };

        Vue.prototype.$heartbeat = function(): void {
            if (this.$store.state.authenticated) {
                if (this.heartbeatInterval) {
                    window.clearInterval(this.heartbeatInterval);
                }

                this.heartbeatInterval = window.setInterval(() => {
                    Request.make('/login', Request.GET).then((res: User) => {
                        if (res.id !== this.$store.state.user.id) {
                            this.$loginInit(res);
                            this.$router.go();
                        }
                    }).catch((res) => {
                        this.$logout();
                    });
                }, 60000);
            }
        };

        Vue.prototype.$checkPasswordResetOnLogin = checkPasswordResetOnLogin;
    }
}
