const PackingSlipList = () => import("@/views/packingslip/list.vue");

export default [
    {
        path: "/packing/slip",
        name: "packing_slip_list",
        component: PackingSlipList,
        meta: { title: "Packing Slips" },
        props: false,
    },
];
