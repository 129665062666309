const Cart = () => import("@/components/checkout/cart.vue");
const CartComplete = () => import("@/components/checkout/complete.vue");

export default [
    {
        path: "/cart",
        name: "cart",
        component: Cart,
        meta: {
            title: "Shopping cart",
        },
    },
    {
        path: "/cart/complete",
        name: "cart_complete",
        component: CartComplete,
        meta: {
            title: "Bestelling voltooid",
        },
    },
];