//import { Vue } from 'vue/types/vue';
import Rules, {Rules as RuleInterface} from "@/rules";
declare module 'vue/types/vue' {
    interface Vue {
        $rules: RuleInterface;
    }
}

export default class RulesPlugin {
    public static install(Vue, options) {
        Vue.prototype.$rules = Rules;
    }
}

