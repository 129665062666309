const OciCart = () => import("@/views/lite/cart.vue");

export default [
    {
        path: "/oci/cart",
        name: "oci_cart",
        component: OciCart,
        meta: { title: "Winkelwagen" },
        props: false,
    },
];
