<script type="typescript" lang="ts">
    import {VSelect} from "vuetify/lib/components";

    const PocoSelect = {
        extends: VSelect,
        name: 'poco-select',
        props: {
            appendIcon: {
                type: String,
                default: 'far fa-chevron-down'
            },
            translate: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            computedItems() {
                if (this.translate) {
                    let i;
                    for (i in this.allItems) {
                        if (typeof this.allItems[i] === 'string') {
                            this.allItems[i] = this.$t(this.allItems[i]);
                        } else if (this.allItems[i].hasOwnProperty(this.itemText)) {
                            this.allItems[i][this.itemText] = this.$t(this.allItems[i][this.itemText]);
                        }
                    }
                }

                return this.allItems;
            },
        },
        methods: {},
    };

    export default PocoSelect;
</script>
