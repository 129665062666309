import LoginLayout from "@/layouts/login.vue";

const Dashboard = () => import('@/views/dashboard.vue');
const ForgotPassword = () => import("@/views/security/forgot-password.vue");
const LoginPage = () => import("@/views/security/login.vue");
const TwoFaPage = () => import("@/views/security/2fa.vue");
const OciLoginPage = () => import("@/views/security/oci-login.vue");
const ResetPassword = () => import("@/views/security/reset-password.vue");
const ChangePassword = () => import("@/views/security/change-password.vue");
const ChangePasswordLogin = () => import("@/views/security/change-password-login.vue");

export default [
    { path: "/login", name: "login", component: LoginPage, meta: { title: "Inloggen", layout: LoginLayout } },
    { path: "/2fa", name: "twofa_login", component: TwoFaPage, meta: { title: "Inloggen", layout: LoginLayout } },
    { path: "/oci/login/:token?", name: "oci_login", component: OciLoginPage, props: true, meta: { title: "Inloggen", layout: LoginLayout } },
    {
        path: "/wachtwoord-vergeten",
        name: "forgot-password",
        component: ForgotPassword,
        meta: { title: "Wachtwoord vergeten", layout: LoginLayout }
    },
    {
        path: "/wachtwoord-reset/:secret",
        name: "reset-password",
        component: ResetPassword,
        meta: { title: "Wachtwoord reset", layout: LoginLayout },
        props: true
    },
    {
        path: '/wachtwoord-veranderen',
        name: "change-password",
        component: ChangePassword,
        meta: { title: 'Wachtwoord wijzigen', layout: LoginLayout },
        props: true
    },
    {
        path: '/wachtwoord-veranderen-login',
        name: "change-password-login",
        component: ChangePasswordLogin,
        meta: { title: 'Wachtwoord wijzigen', layout: LoginLayout },
        props: true
    },
    { path: "/", name: "dashboard", component: Dashboard, meta: { title: "Dashboard" } },
];
