const CommunicationList = () => import("@/views/complaints/list.vue");

export default [
    {
        path: "/communication",
        component: CommunicationList,
        name: "communication",
        meta: { title: "Communication" },
        props: false,
    },
];
