<template>
    <div>
        <v-container fluid v-if="roles.ROLE_ADMIN">
            <v-row>
                <v-spacer />
                <v-col cols="3">
                    <v-btn block color="primary" :to="{name: 'news_create'}">
                        <v-icon left small>far fa-plus</v-icon>
                        {{ $t('Create News Item') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
        <v-card v-for="item in items" :key="item.id" class="mt-3">
            <v-container fluid>
                <v-card-title>
                    <h4 class="title">{{ item.title }}</h4>
                    <template v-if="roles.ROLE_ADMIN">
                        <v-spacer />
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn :to="{name: 'news_edit', params: {id: item.id}}" v-bind="attrs" v-on="on" icon text small>
                                    <v-icon action>fal fa-pencil</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('Edit') }}</span>
                        </v-tooltip>
                    </template>
                </v-card-title>
                <v-card-text>
                    <span class="muted--text" v-html="item.content" />
                </v-card-text>
                <v-card-actions>
                    {{ item.startDate|moment(dateformat) }}
                    -
                    <template v-if="item.endDate">
                        {{item.endDate|moment(dateformat) }}
                    </template>
                    <template v-else>
                        Altijd
                    </template>
                    <v-spacer />
                    <span v-if="roles.ROLE_ADMIN">  {{ $t('ViewTimes').replace('%s', item.viewCount) }} </span>
                </v-card-actions>
            </v-container>
        </v-card>
    </div>
</template>

<script lang="ts" type="typescript">
    import { Component } from 'vue-property-decorator'
    import Vue from "vue"
    import Apollo from "@/lib/apollo";
    import Config from "@/config"
    import { Roles } from "@/store";

    @Component
    export default class NewsOverview extends Vue {
        items: object[] = [];
        dateformat = Config.dateformat;
        roles = <Roles> this.$store.state.user.roles;

        created() {
            Apollo.get('news_items').search({'_order[startDate]' : 'DESC'}).then((res) => {
                this.items = res.items;
            });
        }
    }
</script>
