<template>
    <v-app id="inspire">
        <component v-if="$store.state.initialized" :is="getLayout()">
            <router-view />
        </component>
        <component v-else :is="getLayout()">
            <v-card class="full-width">
                <v-card-text>{{$t('Loading')}}</v-card-text>
            </v-card>
        </component>
    </v-app>
</template>

<script lang="ts">
import { Vue, Component } from "vue-property-decorator";
import DefaultLayout from "@/layouts/default.vue";
import LoginLayout from "@/layouts/login.vue";

@Component
export default class App extends Vue {
    getLayout() {
        if (this.$route.meta.layout) {
            return this.$route.meta.layout;
        }

        if (this.$store.state.authenticated) {
            return DefaultLayout;
        }

        return LoginLayout;
    }
}
</script>
