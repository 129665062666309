const ConfigurationView = () => import("@/views/settings/view.vue");
const EmailOverview = () => import("@/views/email-message/overview.vue");
const SettingsTabBulkCalc = () => import("@/views/settings/components/bulk-calc.vue");
const SettingsTabManual = () => import("@/views/settings/tabs/manual.vue");
const SettingsTabInitialFile = () => import("@/views/settings/tabs/initial-file.vue");

export default [
    {
        path: "/settings",
        name: "settings_view",
        component: ConfigurationView,
        meta: {title: "Instellingen"},
        props: false,
        children: [
            {
                path: '/settings/email_templates',
                name: 'settings_email_templates',
                meta: { title: 'E-mail templates' },
                component: EmailOverview,
                props: false,
            },
            {
                path: '/settings/bulk',
                name: 'settings_bulk',
                meta: { title: 'Bulk calculations' },
                component: SettingsTabBulkCalc,
                props: false,
            },
            {
                path: '/settings/manual',
                name: 'settings_manual',
                meta: {title: 'Upload manual'},
                component: SettingsTabManual,
                props: false,
            },
            {
                path: '/settings/initial-file',
                name: 'settings_initial_file',
                meta: {title: 'Initial file'},
                component: SettingsTabInitialFile,
                props: false,
            },
        ],
    },
];
