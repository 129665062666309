//import { Vue } from 'vue/types/vue';
import { Request } from '@/lib/apollo';

declare module 'vue/types/vue' {
    interface Vue {
        $refreshOrganizationLayerNames(relationID?: string) : void;
        $clearOrganizationLayerNames() : void;
        $getOrganizationLayerName(layer: number) : string;
    }
}

export default class OrganizationLayerPlugin {
    public static install(Vue, options) {
        Vue.prototype.$refreshOrganizationLayerNames = function (relationID?: string): void {
            let isAdmin: boolean = this.$store.state.user.roles.ROLE_ADMIN;

            if (isAdmin && !relationID) {
                console.error('$refreshOrganizationLayerNames: parameter relationID is verplicht wanneer ingelogd als admin gebruiker.');
                return;
            }

            Request.make('/customer/department-layer' + (relationID ? '/' + relationID : '')).then((res: Array<object>) => {
                let layersArray: Array<string> = [];
                for (let key in res[0]) {
                    if (!res[0].hasOwnProperty(key)) {
                        continue;
                    }
                    layersArray.push(res[0][key].name);
                }
                this.$store.state.user.organizationLayers = layersArray;
            });
        };
        Vue.prototype.$clearOrganizationLayerNames = function (): void {
            this.$store.state.user.organizationLayers = [];
        };
        Vue.prototype.$getOrganizationLayerName = function (layer: number): string {
            let layers: Array<string> = this.$store.state.user.organizationLayers;

            if (typeof layers[layer] !== 'undefined') {
                return layers[layer];
            } else if (layer === 0) {
                return this.$t('Sector');
            } else if (layer === 1) {
                return this.$t('Department');
            }
            return this.$t('Layer') + ' ' + layer;
        };
    }
}
