<template>
    <div>
        <v-container fluid>
            <v-row>
                <v-col cols="2">
                    <poco-select
                        :items="categories"
                        v-model="selectedCategory"
                        :label="$t('Category')"
                        append-icon="fas fa-user-group"
                        clearable
                        translate
                        filled
                        dense
                        hide-details
                    />
                </v-col>
                <v-col cols="2">
                    <poco-select
                        :items="types"
                        v-model="selectedType"
                        :label="$t('Type')"
                        append-icon="fas fa-list"
                        clearable
                        translate
                        filled
                        dense
                        hide-details
                    />
                </v-col>
                <v-col cols="2">
                    <date-picker
                        v-model="date.start"
                        today label="Start"
                        filled
                        dense
                        hide-details
                    />
                </v-col>
                <v-col cols="2">
                    <date-picker
                        v-model="date.end"
                        label="End"
                        filled
                        dense
                        hide-details
                    />
                </v-col>
                <v-col cols="3">
                    <v-text-field
                        v-model="search"
                        append-icon="fas fa-search"
                        :label="$t('Search')"
                        single-line
                        filled
                        dense
                        hide-details
                    />
                </v-col>
            </v-row>
        </v-container>
        <v-card class="full-width mt-3">
            <data-table
                v-bind:headers="headers"
                v-bind:items="logs"
                v-bind:options.sync="pagination"
                :server-items-length="total"
                :rows-per-page-items="[10, 25, 50, 100]"
                :loading="loading"
                :no-data-text="$dataText(loading)"
                :search="search"
            >
                <template v-slot:item="{ item, index }">
                    <tr @click="openDetailsDialog(item.id)" style="cursor: pointer">
                        <td class="text-left">{{ item.id }}</td>
                        <td class="text-left">{{ item.date.date | moment(datetimeformat) }}</td>
                        <td class="text-left">{{ item.type }}</td>
                        <td class="text-left">{{ item.category }}</td>
                        <td class="text-left">{{ item.person.fullName }}</td>
                        <td class="text-left">{{ item.ipAddress || '-' }}</td>
                        <td class="text-left">{{ item.extraRow.entity || '-' }}</td>
                    </tr>
                </template>
            </data-table>
        </v-card>
        <log-details-dialog />
    </div>
</template>

<script lang="ts" type="typescript">
    import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
    import { Request } from '@/lib/apollo';
    import Config from '@/config';
    import LogDetailsDialog from '@/components/log/details-dialog.vue';
    import PocoSelect from '@/components/poco-select.vue';

    interface DateRange {
        start: string;
        end: string;
    }

    @Component({
        components: {
            LogDetailsDialog,
            PocoSelect
        }
    })
    export default class LogList extends Vue {
        @Prop()
        relation: string;

        @Prop()
        category: string;

        @Prop()
        type: string;

        search = null;
        selectedCategory = null;
        categories = [
            { text: 'All', value: null },
            { text: 'Admin', value: 'admin' },
            { text: 'View', value: 'view' },
        ];
        selectedType = null;
        types = [
            { text: 'Create', value: 'create' },
            { text: 'Update', value: 'update' },
            { text: 'Delete', value: 'delete' },
            { text: 'Login', value: 'login' },
            { text: 'Email', value: 'email' },
        ];
        ipAddress = null;

        date: DateRange = {
            start: '',
            end: '',
        };

        datetimeformat = Config.datetimeformat;

        logs: Array<object> = [];
        total: number = 0;
        pagination: any = {
            sortDesc: [true],
            sortBy: ['l.date'],
            page: 1,
            itemsPerPage: 10,
            totalItems: 0,
        };
        loading: boolean = false;
        timeout: number = 0;

        headers: Array<object> = [
            { text: 'Id', align: 'left', value: 'l.id' },
            { text: 'Date', align: 'left', value: 'l.date' },
            { text: 'Type', align: 'left', value: 'l.type' },
            { text: 'Category', align: 'left', value: 'c.name' },
            { text: 'Person', align: 'left', value: 'p.firstName' },
            { text: 'IP address', align: 'left', value: 'l.ipAddress' },
            { text: 'Entity', align: 'left', value: 'extraRow.entity', sortable: false },
        ];

        created() {
            if (this.category) {
                this.selectedCategory = this.category;
            }

            if (this.type) {
                this.selectedType = this.type;
            }

            this.getLogs();
            this.$watch('selectedCategory', () => {
                this.getLogs();
            });
            this.$watch('selectedType', () => {
                this.getLogs();
            });
        }

        @Watch('date', {deep: true})
        onDate() {
            if (this.date.start && this.date.end) {
                this.getLogs();
            }
        }

        @Watch('pagination.itemsPerPage')
        getLogs() {
            let params = {
                category: this.selectedCategory,
                type: this.selectedType,
                relation: this.relation,
                search: this.search,
                date: {}
            };

            if (this.pagination.itemsPerPage > -1) {
                params['pg_page'] = this.pagination.page;
                params['pg_perpage'] = this.pagination.itemsPerPage;
                params['pg_desc'] = this.pagination.sortDesc[0];
                params['pg_sort'] = this.pagination.sortBy[0];
            }

            if (this.date.start && this.date.end) {
                params.date = this.date;
            }

            this.loading = true;
            Request.make('/log/get', Request.POST, params).then((res) => {
                this.logs = res.items;
                this.total = res.total;
                this.loading = false;
            });
        }

        @Watch('search')
        onSearch() {
            clearTimeout(this.timeout);

            this.timeout = <any> setTimeout(() => {
                this.getLogs();
            }, 1000);
        }

        @Watch('pagination', { deep: true })
        onPaginate(newVal, oldVal) {
            if (newVal.page === oldVal.page
                && newVal.sortBy[0] === oldVal.sortBy[0]
                && newVal.sortDesc[0] === oldVal.sortDesc[0]
                && newVal.rowsPerPage === oldVal.rowsPerPage) {
                return false;
            }

            if (!this.loading) {
                this.getLogs();
            }
        }

        openDetailsDialog(id) {
            this.$root.$emit('log-details-dialog-enable', {
                id: id,
            });
        }
    }
</script>
