import VueRouter from "vue-router";
import store from "@/store";
import '@/lib/hooks';

// Layouts
import LoginLayout from "@/layouts/login.vue";
import PublicLayout from "@/layouts/public.vue";

// Routes
import AddressRoutes from "@/views/address/routes";
import CartRoutes from "@/views/cart/routes";
import CatalogRoutes from "@/views/catalog/routes";
import CirculationRoutes from "@/views/circulation/routes";
import ComplaintRoutes from "@/views/chat/routes";
import ComplaintsRoutes from "@/views/complaints/routes";
import ContactRoutes from "@/views/contact/routes";
import CustomerRoutes from "@/views/customer/routes";
import DetailsRoutes from "@/views/details/routes";
import EmailMessageRoutes from "@/views/email-message/routes";
import FinancialRoutes from "@/views/financial/routes";
import ManualRoutes from "@/views/manual/routes";
import NewsRoutes from "@/views/news/routes";
import OrderRoutes from "@/views/order/routes";
import PackingSlipRoutes from "@/views/packingslip/routes";
import PrintQueueRoutes from "@/views/print-queue/routes";
import ProductRoutes from "@/views/product/routes";
import PurchaseRoutes from "@/views/purchases/routes";
import ReaderRoutes from "@/views/readerlist/routes";
import SecurityRoutes from "@/views/security/routes";
import SettingsRoutes from "@/views/settings/routes";
import SupplierRoutes from "@/views/supplier/routes";
import UserRoutes from "@/views/user/routes";
import LogRoutes from "@/views/logs/routes";
import OciRoutes from "@/views/lite/routes";
import UserSettingRoutes from "@/views/user-settings/routes";
import BulkToolRoutes from "@/views/bulk/routes";
import JobRoutes from "@/views/job/routes";
import EmailRoutes from "@/views/email/routes";
import DigicomRoutes from "@/views/digicom/routes";
import PublicRoutes from "@/views/public/routes";
import Vue from "vue";

export const routes = [
  ...AddressRoutes,
  ...CartRoutes,
  ...CatalogRoutes,
  ...CirculationRoutes,
  ...ComplaintRoutes,
  ...ComplaintsRoutes,
  ...ContactRoutes,
  ...CustomerRoutes,
  ...DetailsRoutes,
  ...EmailMessageRoutes,
  ...FinancialRoutes,
  ...OrderRoutes,
  ...ManualRoutes,
  ...PackingSlipRoutes,
  ...PrintQueueRoutes,
  ...ProductRoutes,
  ...NewsRoutes,
  ...PurchaseRoutes,
  ...ReaderRoutes,
  ...SecurityRoutes,
  ...SettingsRoutes,
  ...SupplierRoutes,
  ...UserRoutes,
  ...LogRoutes,
  ...OciRoutes,
  ...UserSettingRoutes,
  ...BulkToolRoutes,
  ...JobRoutes,
  ...EmailRoutes,
  ...DigicomRoutes,
  ...PublicRoutes
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (store.state.authenticated && store.state.user.passwordResetOnLogin && to.name !== 'change-password') {
    return router.push({name: 'change-password', params: {}})
  }

  router.app.$store.commit('showSearchResults', false);
  router.app.$emit("loading", true);

  if (to.meta.layout !== LoginLayout
      && to.meta.layout !== PublicLayout
      && !router.app.$store.state.authenticated) {
    next("/login");
    return;
  }

  next();
});

router.afterEach((to, from) => {
  router.app.$emit("loading", false);
});

Vue.use(VueRouter);

export default router;
