<template>
    <div class="fill-height" v-if="$store.state.authenticated === true">
        <v-snackbar :timeout="6000" v-bind="$store.state.flashMessageOptions" :top="true" v-model="$store.getters.hasFlash">
            {{ $store.state.flashMessage }}
            <v-btn light text @click.native="$store.commit('flashMessage', '')">{{ $t('Close') }}</v-btn>
        </v-snackbar>

        <app-menu />
        <app-toolbar :search="search" />
        <v-main>
            <v-container fluid style="padding-left: 20px; padding-right: 20px">
                <h1 class="headline mb-3">{{ routeTitle }}</h1>
                <app-manual v-if="$store.state.initialized" />
                <search-results v-show="showSearchResults" :search-result="$store.state.searchResult" />
                <v-container class="pa-0" fluid v-show="!showSearchResults">
                    <slot />
                </v-container>
            </v-container>

            <!--Tijdelijk disabled tot dat er een fix voor is gevonden-->
            <!--<app-heart-beat />-->
        </v-main>
        <offline-dialog />
        <cookie-warning />
        <v-footer inset app style="margin: 0 auto; background: transparent">
            <div class="muted--text" style="margin: 0 auto">
                Versie: {{ version }}<span v-if="$store.state.user.isReadOnly" style="color:#F00 !important"> - Alleen lezen</span>
            </div>
        </v-footer>
        <v-tour name="dashboard" :steps="tourSteps">
            <template slot-scope="tour">
                <transition name="fade">
                    <v-step
                            v-if="tour.currentStep === index"
                            v-for="(step, index) of tour.steps"
                            :key="index"
                            :step="step"
                            :previous-step="tour.previousStep"
                            :next-step="tour.nextStep"
                            :stop="tour.stop"
                            :isFirst="tour.isFirst"
                            :isLast="tour.isLast"
                    >
                        <template v-if="tour.isFirst">
                            <div slot="actions">
                                <v-btn @click="tour.stop" color="primary">Overslaan</v-btn>
                                <v-btn @click="tour.nextStep" color="primary">Volgende stap</v-btn>
                            </div>
                        </template>
                        <template v-if="!tour.isLast && !tour.isFirst">
                            <div slot="actions">
                                <v-btn @click="tour.previousStep" color="primary">Vorige stap</v-btn>
                                <v-btn @click="tour.nextStep" color="primary">Volgende stap</v-btn>
                            </div>
                        </template>
                        <template v-if="tour.isLast">
                            <div slot="actions">
                                <v-btn @click="tour.previousStep" color="primary">Vorige stap</v-btn>
                                <v-btn @click="tour.stop" color="primary">Voltooien</v-btn>
                            </div>
                        </template>
                    </v-step>
                </transition>
            </template>
        </v-tour>

        <add-to-cart />
    </div>
</template>


<style scoped>
.download-manual {
    position: absolute;
    bottom: 10px;
    z-index: 5;
}
</style>
<script lang="ts" type="typescript">
const AppMenu = () => import('@/components/app-menu.vue');
const AppManual = () => import('@/components/app-manual.vue');
const AppToolbar = () => import('@/components/app-toolbar.vue');
const OfflineDialog = () => import("@/components/offline.vue");
const SearchResults = () => import("@/components/search-results.vue");
const CookieWarning = () => import("@/components/cookie-warning.vue");
const AddToCart = () => import("@/components/add-to-cart.vue");

//import AppHeartBeat from "@/components/app-heartbeat.vue"; // Tijdelijk disabled tot dat er een fix voor is gevonden
import Config from "@/config"

import {Component, Vue, Watch} from 'vue-property-decorator';
import { Request } from '@/lib/apollo';

export interface SearchOptions {
    onSearch?: (query: string) => void,
    onEnter?: (query: string) => void,
    placeholder: string,
    value: string,
    enabled: boolean,
    loading: boolean,
}

@Component({
    components: {
        AppMenu,
        AppToolbar,
        AppManual,
        OfflineDialog,
        SearchResults,
        CookieWarning,
        AddToCart,
        //AppHeartBeat, // Tijdelijk disabled tot dat er een fix voor is gevonden
    }
})
export default class DefaultLayout extends Vue {
    version: string = Config.version;

    tourSteps = [];

    searchTimeout;
    searchUnwatch;

    search: SearchOptions = {
        enabled: false,
        loading: false,
        value: '',
        placeholder: 'Zoeken...',
        onSearch: null,
        onEnter: null
    };

    created() {
        this.tourSteps = [
            {
                target: '[data-v-step="1"]',
                content: this.$t('TutorialStep1'),
            },
            {
                target: '[data-v-step="2"]',
                content: this.$t('TutorialStep2'),
            },
            {
                target: '[data-v-step="3"]',
                content: this.$t('TutorialStep3'),
            },
            {
                target: '[data-v-step="4"]',
                content: this.$t('TutorialStep4'),
            },
            {
                target: '[data-v-step="5"]',
                content: this.$t('TutorialStep5'),
                params: {
                    placement: 'left'
                }
            },
            {
                target: '[data-v-step="6"]',
                content: this.$t('TutorialStep6'),
            },
            {
                target: '[data-v-step="7"]',
                content: this.$t('TutorialStep7'),
            },
            {
                target: '[data-v-step="8"]',
                content: this.$t('TutorialStep8'),
            },
            {
                target: '[data-v-step="9"]',
                content: this.$t('TutorialStep9'),
            },
            {
                target: '[data-v-step="10"]',
                content: this.$t('TutorialStep10'),
            },
            {
                target: '[data-v-step="11"]',
                content: this.$t('TutorialStep11'),
                params: {
                    placement: 'right'
                }
            },
            {
                target: '[data-v-step="12"]',
                content: this.$t('TutorialStep12'),
                params: {
                    placement: 'right'
                }
            },
        ];

        this.$root.$off('enable-search');
        this.$root.$on('enable-search', (params: SearchOptions) => {
            params['enabled'] = true;
            if (this.$store.state.searchQueryForRoute.hasOwnProperty(this.$route.name)) {
                params['value'] = this.$store.state.searchQueryForRoute[this.$route.name];
            }
            Object.assign(this.search, params);

            this.searchUnwatch = this.$watch('search.value', this.search.onSearch);
        });

        this.$root.$off('disable-search');
        this.$root.$on('disable-search', () => {
            this.search = {
                enabled: false,
                loading: false,
                value: '',
                placeholder: 'Zoeken...',
                onSearch: null,
                onEnter: null,
            };

            if (typeof this.searchUnwatch === 'function') {
                this.searchUnwatch();
            }
        });

        this.$root.$off('enable-search-loading');
        this.$root.$on('enable-search-loading', () => {
            this.search.loading = true;
        });

        this.$root.$off('disable-search-loading');
        this.$root.$on('disable-search-loading', () => {
            this.search.loading = false;
        });
    }

    mounted() {
        window.setTimeout(() => {
            Request.make('/dashboard', Request.GET).then((res: any) => {
                if (res !== undefined) {
                    if (res.loginCount === 1 && !this.$store.state.user.roles.ROLE_ADMIN) {
                        Request.make('/user/add-login-count/' + this.$store.state.user.id, Request.GET).then((res) => {
                            this.$tours['dashboard'].start();
                        });
                    }
                }
            });
        }, 3000);

        let launcher = document.getElementById('launcher');
        if (launcher) {
            if (!this.$store.state.user.roles.ROLE_ADMIN && (this.$store.state.user.roles.ROLE_RELATION_MAIN_USER || this.$store.state.user.roles.ROLE_RELATION_SUB_USER)) {
                launcher.style.display = '';
            } else {
                launcher.style.display = 'none';
            }
        }

        // Issuecollector toevoegen
        if (!document.getElementById('atlassian-issue-collector')) {
            let issueCollector: HTMLScriptElement = document.createElement('script');
            issueCollector.id = 'atlassian-issue-collector';
            issueCollector.src = 'https://skrepr.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/1kwsvv/b/8/c95134bc67d3a521bb3f4331beb9b804/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector.js?locale=nl-NL&collectorId=e53a2bfa';
            document.body.appendChild(issueCollector);
        }
    }

    get routeTitle(): string {
        if (this.showSearchResults) {
            return <string>this.$t('Search results');
        }

        let title = this.$route.params['metaTitle'] || this.$route.meta.title;
        if (!this.$route.meta.disableTranslate) {
            title = this.$t(title);
        }
        return title;
    }

    get showSearchResults() {
        if (!this.$store.state.user.roles.ROLE_ADMIN) {
            return false;
        }

        if (this.$store.state.searchQuery.length == 0) {
            return false;
        }

        return this.$store.state.showSearchResults;
    }

    @Watch('search.value', { deep: true })
    clearSearch(val) {
        if (!val) {
            this.$store.commit('setSearchQueryForRoute', {
                route: this.$route.name,
                query: '',
            });
        }
    }
}
</script>
