const UserSettings = () => import("@/views/user-settings/view.vue");
const ChangePassword = () => import("@/views/security/change-password.vue");
const TwoFactorAuth = () => import("@/views/security/two-factor-auth.vue");

export default [
    {
        path: "/mijn-instellingen",
        name: 'user_details',
        component: UserSettings,
        meta: { title: "Mijn instellingen" },
        props: false,
        children: [
            {
                path: "wachtwoord-wijzigen",
                name: "user-change-password",
                component: ChangePassword,
                meta: { title: "Change password" }
            },
            {
                path: "two-factor-auth",
                name: "two-factor-auth",
                component: TwoFactorAuth,
                meta: { title: "Two-factor authentication" }
            },
        ],
    },
];
