const EmailOverview = () => import("./overview.vue");

export default [
    {
        path: "/email/overview",
        name: "email_overview",
        meta: { title: "Outgoing e-mails" },
        component: EmailOverview,
        props: true,
    },
];