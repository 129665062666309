import Resource, { ApiResource } from "./apollo/resource";
export { default as Request } from "./apollo/request";
import parseHydraDocumentation from "@api-platform/api-doc-parser/lib/esm/hydra/parseHydraDocumentation";

import config from "../config";

export { default as Resource } from "./apollo/resource";
export { default as ResourceCollection } from "./apollo/resourceCollection";

class Apollo {
    private static _initialized: boolean = false;
    private static _resources: { [name: string]: Resource } = {};

    public static init(): Promise<void> {
        return parseHydraDocumentation(`${config.api.host}/api`).then((result) => {
            const resources = result.api.resources;

            for (const rawResource of resources) {
                const resourceInterface = rawResource as ApiResource;

                Apollo._resources[resourceInterface.name] = new Resource(resourceInterface);
            }

            Apollo._initialized = true;
        });
    }

    public static get(resourceName: string): Resource {
        const resources = Object.keys(Apollo._resources);
        const resource = Apollo._resources[resourceName];

        if (typeof resource === "undefined") {
            console.warn(`Resource ${resourceName} does not exist. Available resources are: ${JSON.stringify(resources)}`);
        }

        return resource;
    }

    public static all() {
        return Apollo._resources;
    }

    public static isInitialized() {
        return Apollo._initialized;
    }
}

let handler = {
    get(target, name, receiver): Resource|any {
        if (typeof Apollo[name] !== 'undefined') {
            return Reflect.get(target, name, receiver);
        }

        return target.get(name);
    }
};

// let apolloProxy = typeof Proxy !== 'undefined' ? new Proxy(Apollo, handler) : Apollo;


export default Apollo;
