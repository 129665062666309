import User from "@/entity/user";
import Apollo, {Request, ResourceCollection} from "@/lib/apollo";
import AuthenticationPlugin from "@/plugins/authentication";
import CachePlugin from "@/plugins/cache";
import CookiePlugin from "@/plugins/cookie";
import DataTextPlugin from "@/plugins/data-text";
import FlashMessagesPlugin from "@/plugins/flash-messages";
import LocalePlugin from "@/plugins/locale";
import OrganizationLayerPlugin from "@/plugins/organization-layer";
import RoleCheckerPlugin from "@/plugins/role-checker";
import RulesPlugin from "@/plugins/rules";
import ShoppingCartPlugin from "@/plugins/shopping-cart";
import '@fortawesome/fontawesome-pro/css/all.css';
import Vue from 'vue';
import VueMoment from "vue-moment";
import VueTour from "vue-tour";
import App from './App.vue';
import "./components";
import i18n from './i18n';
import vuetify from './plugins/vuetify';
import router from './router';
import './sass/main.scss';
import store from './store';

Vue.config.productionTip = false;

Vue.use(AuthenticationPlugin);
Vue.use(CachePlugin);
Vue.use(CookiePlugin);
Vue.use(DataTextPlugin);
Vue.use(FlashMessagesPlugin);
Vue.use(LocalePlugin);
Vue.use(OrganizationLayerPlugin);
Vue.use(RulesPlugin);
Vue.use(RoleCheckerPlugin);
Vue.use(ShoppingCartPlugin);
Vue.use(VueTour);
Vue.use(VueMoment);

Vue.filter('nl2br', function (value: string) {
  return value.replaceAll("\n", '<br />');
});

Request.make('/login', Request.GET).then((loginRes?: User) => {
  if (loginRes) {
    store.commit('authenticated', loginRes.authenticated);
    store.commit('user', loginRes);
  } else {
    store.commit('authenticated', false);
    store.commit('user', new User());
  }

  new Vue({
    router,
    store,
    i18n,
    vuetify,
    render: (h) => h(App),
    data() {
      return {
        loading: false,
      };
    },
    beforeCreate() {
      const self: any = this;
      this.$on("set-loading", (loading) => {
        self.loading = loading;
      });

      Apollo.init().then(() => {
        this.$initAuth(loginRes).then(() => {
          this.$store.commit("initialized", true);

          if (this.$store.state.authenticated) {
            this.$cacheSet('suppliers', async () => {
              const suppliers = await Apollo.get('suppliers').all({
                properties: ['id', 'number', 'name'],
              });

              return suppliers.items;
            }, { lazy: true });

            Apollo.get('carts').all().then((res: ResourceCollection) => {
              if (res) {
                this.$store.commit("overrideCart", res.items);
              }
            });
          }
        });
      });
    },
  }).$mount('#app');
});
