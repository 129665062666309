//import { Vue } from "vue/types/vue";

declare module "vue/types/vue" {
    interface Vue {
        $hasRole(role: string): boolean;
    }
}

export default class RoleCheckerPlugin {
    public static install(Vue, options) {
        Vue.prototype.$hasRole = function(role: string) {
            if (!(role in this.$store.state.user.roles)) {
                console.warn(`Role bestaat niet: '${role}'`);
                return false;
            }

            return this.$store.state.user.roles[role];
        };
    }
}
