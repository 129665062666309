<template>
    <log-list :category="null" />
</template>

<script lang="ts" type="typescript">
    import { Vue, Component } from 'vue-property-decorator';
    import LogList from '@/components/log/log.vue';

    @Component({
        components: {
            LogList,
        }
    })
    export default class LogView extends Vue {

    }
</script>
