import Vue from "vue";
import Vuex from "vuex";
import _ from "lodash";
import createPersistedState from "vuex-persistedstate";

import { Request } from '@/lib/apollo';

import Searchresult from "@/entity/searchresult";
import User from "@/entity/user";

Vue.use(Vuex);

export interface Roles {
  'ROLE_SUPERADMIN': boolean;
  'ROLE_ADMIN': boolean;
  'ROLE_USER': boolean;
  'ROLE_ALLOWED_TO_SWITCH': boolean;
  'ROLE_RELATION_MAIN_USER': boolean;
  'ROLE_RELATION_SUB_USER': boolean;
  'ROLE_OCI_USER': boolean;
}

function defaultState() {
  return {
    drawer: true,
    flashMessage: "",
    authenticated: false,
    initialized: false,
    language: "NL",
    layout: "default",
    locale: "nl",
    themeColor: "light-green darken-1",
    flashMessageOptions: {},
    cart: [],
    catalogSearchState: {
      products: false,
      subscriptions: false,
      books: false,
    },
    showSearchResults: false,
    searchResult: {relations: [], orders: []},
    searchQuery: '',
    searchQueryForRoute: {},
    lastSearch: '',
    logo: '',
    user: new User(),
    organizationLayerCache: {},
    cache: new Map<string, any>(),
    cacheCallbacks: new Map<string, () => any>(),
    newOrderCount: 0,
    paginationOptions: {
      rowsPerPage: 10,
    },
    manualExists: false,
    organizationName: '',
  };
}

const Store = new Vuex.Store({
  state: defaultState(),
  plugins: [createPersistedState({paths: ["drawer", "themeColor", "locale"]})],
  mutations: {
    reset(state) {
      state = defaultState();
    },
    toggleDrawer(state) {
      state.drawer = !state.drawer;
    },
    authenticated(state, auth) {
      state.authenticated = auth;
    },
    resetAuth(state) {
      state.authenticated = false;
      state.user = new User();
      state.organizationName = '';
    },
    themeColor(state, color) {
      state.themeColor = color;
    },
    passwordResetOnLogin(state, bool) {
      state.user.passwordResetOnLogin = bool;
    },
    flashMessage(state, msg) {
      let message = "";

      if (typeof msg === "string") {
        message = msg;
      } else {
        message = msg.msg;
      }

      state.flashMessage = message;

      if (msg.options) {
        state.flashMessageOptions = msg.options;
      } else {
        state.flashMessageOptions = {
          success: true,
        };
      }

      setTimeout(() => {
        state.flashMessage = "";
      }, 6000);
    },
    initialized(state, init) {
      state.initialized = init;
    },
    user(state, value) {
      state.user = value;
    },
    organizationName(state, value) {
      state.organizationName = value;
    },
    addToCart(state, value) {
      if (typeof state.cart === 'undefined') {
        state.cart = [];
      }

      state.cart.push(value);
    },
    removeFromCart(state, id) {
      const index = _.findIndex(state.cart, ['id', id]);

      if (state.cart[index]) {
        state.cart.splice(index);
      }
    },
    overrideCart(state, value) {
      if (typeof value !== 'undefined') {
        state.cart = value;
      }
    },
    clearCart(state, value) {
      state.cart = [];
    },
    locale(state, value) {
      state.locale = value;
    },
    search(state, query) {
      if (query === null) {
        state.showSearchResults = false;
        state.searchQuery = '';
        return;
      }

      state.showSearchResults = true;
      state.searchQuery = query;

      Request.make('/api/search', Request.GET, {q: query}, true).then((res: Searchresult) => {
        state.searchResult = res;
      });
    },
    setSearchQueryForRoute(state, data) {
      state.searchQueryForRoute[data.route] = data.query;
    },
    showSearchResults(state, visible) {
      state.showSearchResults = visible;
    },
    lastSearch(state, query) {
      state.lastSearch = query;
    },
    setCatalogSearchState(state, value) {
      state.catalogSearchState[value.mode] = value.state;
    },
    cache(state, params) {
      if (params.callback) {
        state.cacheCallbacks.set(params.key, params.callback);
      }

      state.cache.set(params.key, params.value);
    },
    newOrderCount(state, count) {
      state.newOrderCount = count;
    },
    updatePagination(state, paginationOptions) {
      state.paginationOptions = paginationOptions;
    },
    updateManualExists(state, bool) {
      state.manualExists = bool;
    },
  },
  getters: {
    hasFlash: (state) => {
      return state.flashMessage.length >= 1;
    },
    getLang: (state) => {
      return state.language;
    },
  },
});
export default Store;
