<script type="typescript" lang="ts">
    //import VDataTable from 'vuetify/es5/components/VDataTable';
    //import _VSelect2 from 'vuetify/es5/components/VSelect';

    import { DataTableHeader } from "vuetify";
    import { VDataTable } from "vuetify/lib/components";

    export default {
        extends: VDataTable,
        name: 'data-table',
        props: {
            noDataText: {
                type: String,
                default: 'Geen resultaten'
            },
            noResultsText: {
                type: String,
                default: 'Geen resultaten gevonden'
            },
            rowsPerPageText: {
                type: String,
                default: 'Rows per page'
            },
            rowsPerPageItems: {
                type: Array,
                default () {
                    return [
                        5,
                        10,
                        25,
                        { text: 'All', value: -1 }
                    ]
                }
            },
            sortIcon: {
                type: String,
                default: 'far fa-chevron-down'
            },
            pagination: {
                type: Object,
                default: () => {}
            }
        },
        computed: {
            computedHeaders (): DataTableHeader[] {
                // Copy pasted uit VDataTable.ts, krijg onderstaande niet aan de praat:
                //let headers = super.computedHeaders();
                //let headers = this.computedHeaders();

                if (!this.headers) return []
                const headers = this.headers.filter(h => h.value === undefined || !this.internalGroupBy.find(v => v === h.value))
                const defaultHeader = { text: '', sortable: false, width: '1px' }

                if (this.showSelect) {
                    const index = headers.findIndex(h => h.value === 'data-table-select')
                    if (index < 0) headers.unshift({ ...defaultHeader, value: 'data-table-select' })
                    else headers.splice(index, 1, { ...defaultHeader, ...headers[index] })
                }

                if (this.showExpand) {
                    const index = headers.findIndex(h => h.value === 'data-table-expand')
                    if (index < 0) headers.unshift({ ...defaultHeader, value: 'data-table-expand' })
                    else headers.splice(index, 1, { ...defaultHeader, ...headers[index] })
                }

                // Vertalen
                let i;
                for (i in headers) {
                    headers[i]['text'] = this.$t(headers[i]['text']);
                }

                return headers;
            }
        },
        methods: {
        },
    };
</script>
