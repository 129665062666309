<template>
    <v-dialog v-model="modal" max-width="1000px">
        <v-card>
            <v-card-title>{{ $t('View log item') }}</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
                <fieldset>
                    <legend>{{ $t('Information') }}</legend>
                    <v-layout row>
                        <v-flex xs2>
                            <div class="subheader" style="height: 30px">Id</div>
                        </v-flex>
                        <v-flex xs10>
                            <div class="body-3">{{ data.id }}</div>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs2>
                            <div class="subheader" style="height: 30px">{{ $t('Category') }}</div>
                        </v-flex>
                        <v-flex xs10>
                            <div style="text-transform: capitalize" class="body-3">{{ data.category }}</div>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs2>
                            <div class="subheader" style="height: 30px">{{ $t('Type') }}</div>
                        </v-flex>
                        <v-flex xs10>
                            <div style="text-transform: capitalize" class="body-3">{{ data.type }}</div>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs2>
                            <div class="subheader" style="height: 30px">{{ $t('Date') }}</div>
                        </v-flex>
                        <v-flex xs10 v-if="typeof data.date.date !== 'undefined'">
                            <div class="body-3">{{ data.date.date | moment(datetimeformat) }}</div>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs2>
                            <div class="subheader" style="height: 30px">{{ $t('Description') }}</div>
                        </v-flex>
                        <v-flex xs10>
                            <div class="body-3">{{ data.description }}</div>
                        </v-flex>
                    </v-layout>
                </fieldset>
                <br>
                <fieldset>
                    <legend>{{ $t('User') }}</legend>
                    <v-layout row>
                        <v-flex xs2>
                            <div class="subheader" style="height: 30px">{{ $t('Person') }}</div>
                        </v-flex>
                        <v-flex xs10>
                            <div class="body-3">{{ data.person.fullName }}</div>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs2>
                            <div class="subheader" style="height: 30px">{{ $t('Username') }}</div>
                        </v-flex>
                        <v-flex xs10>
                            <router-link style="cursor: pointer; text-decoration: underline" class="body-3" :to="{name: 'user_update_general', params: { id: data.person.id }}">{{ data.person.user.userName }} <v-icon>visibility</v-icon></router-link>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs2>
                            <div class="subheader" style="height: 30px">{{ $t('User type') }}</div>
                        </v-flex>
                        <v-flex xs10>
                            <div class="body-3">{{ data.person.user.type }}</div>
                        </v-flex>
                    </v-layout>
                    <v-layout row v-if="data.ipAddress">
                        <v-flex xs2>
                            <div class="subheader" style="height: 30px">{{ $t('IP address') }}</div>
                        </v-flex>
                        <v-flex xs10>
                            <div class="body-3">{{ data.ipAddress }}
                                <a :href="'https://ipinfo.io/' + data.ipAddress" target="_blank" class="btn btn--flat btn--small primary--text"><div class="btn__content"><i aria-hidden="true" class="icon material-icons">search</i> {{ $t('View IP information') }}</div></a>
                            </div>
                        </v-flex>
                    </v-layout>
                </fieldset>
                <br>
                <fieldset v-if="data.type !== 'login' && data.type !== 'email'">
                    <legend>{{ data.type === 'create' ? $t('Created') : data.type === 'update' ? $t('Updated') : data.type === 'delete' ? $t('Removed') : '' }} Entity</legend>
                    <v-layout row>
                        <v-flex xs2>
                            <div class="subheader" style="height: 30px">Entity</div>
                        </v-flex>
                        <v-flex xs10>
                            <div class="body-3">{{ data.extraRow.entity }}</div>
                        </v-flex>
                    </v-layout>
                    <template v-if="data.type === 'delete'">
                        <v-layout row>
                            <v-flex xs2>
                                <div class="subheader" style="height: 30px">Id</div>
                            </v-flex>
                            <v-flex xs10>
                                <div class="body-3">{{ data.extraRow.originalData.id }}</div>
                            </v-flex>
                        </v-layout>
                    </template>
                    <template v-else>
                        <v-layout row>
                            <v-flex xs2>
                                <div class="subheader" style="height: 30px">Id</div>
                            </v-flex>
                            <v-flex xs10>
                                <div class="body-3">{{ data.extraRow.id }}</div>
                            </v-flex>
                        </v-layout>
                    </template>
                    <br>
                </fieldset>
                <fieldset v-if="data.extraRow.changes">
                    <legend>{{ $t('Changed fields') }}</legend>
                    <template v-for="(item, index) in data.extraRow.changes">
                        <v-layout row>
                            <v-flex xs2>
                                <div class="subheader" style="height: 30px">{{ index }}</div>
                            </v-flex>
                            <v-flex xs4>
                                <code>{{ item[0] instanceof String ? item[0] : item[0] instanceof Object && item[0].date ? item[0].date : item[0] }}</code>
                            </v-flex>
                            <v-flex xs2>
                                <v-icon>arrow_right_alt</v-icon>
                            </v-flex>
                            <v-flex xs4>
                                <code>{{ item[1] instanceof String ? item[1] : item[1] instanceof Object && item[1].date ? item[1].date : item[1] }}</code>
                            </v-flex>
                        </v-layout>
                    </template>
                </fieldset>
                <fieldset v-if="data.extraRow.created">
                    <legend>{{ $t('Created fields') }}</legend>
                    <template v-for="(item, index) in data.extraRow.created">
                        <v-layout row class="mb-1">
                            <v-flex xs2>
                                <div class="subheader" style="height: 30px">{{ index }}</div>
                            </v-flex>
                            <v-flex xs10>
                                <code>{{ item }}</code>
                            </v-flex>
                        </v-layout>
                    </template>
                </fieldset>
                <fieldset v-if="data.type === 'delete'">
                    <legend>{{ $t('Original data') }}</legend>
                    <template v-for="(item, index) in data.extraRow.originalData">
                        <v-layout row class="mb-1">
                            <v-flex xs2>
                                <div class="subheader" style="height: 30px">{{ index }}</div>
                            </v-flex>
                            <v-flex xs10>
                                <code>{{ item }}</code>
                            </v-flex>
                        </v-layout>
                    </template>
                </fieldset>
                <fieldset v-if="data.type === 'email'">
                    <legend>{{ $t('E-mail') }}</legend>
                    <v-layout row>
                        <v-flex xs2>
                            <div class="subheader" style="height: 30px">{{ $t('Error message') }}</div>
                        </v-flex>
                        <v-flex xs10>
                            <strong>{{ data.extraRow.error }}</strong>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs2>
                            <div class="subheader" style="height: 30px">{{ $t('Recipients') }}</div>
                        </v-flex>
                        <v-flex xs10>
                            <ul style="margin-left: 15px;">
                                <template v-for="item in data.extraRow.recipients">
                                    <li>{{ item }}</li>
                                </template>
                            </ul>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs2>
                            <div class="subheader" style="height: 30px">{{ $t('Subject') }}</div>
                        </v-flex>
                        <v-flex xs10>
                            {{ data.extraRow.subject }}
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs12>
                            <div class="subheader" style="height: 30px">{{ $t('Content') }}</div>
                        </v-flex>
                    </v-layout>
                    <v-flex xs12>
                        <div v-html="data.extraRow.body"></div>
                    </v-flex>
                </fieldset>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-spacer />
                <v-btn color="primary" small style="min-width: 100px" @click="modal = false">{{ $t('Close') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script lang="ts" type="typescript">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import { Request } from '@/lib/apollo';
    import Config from '@/config';

    @Component
    export default class LogDetailsDialog extends Vue {
        @Prop()
        id: string;

        data: any = {
            date: {},
            person: {
                id: '0',
                user: '',
            },
            extraRow: '',
        };
        datetimeformat = Config.datetimeformat;

        modal: boolean = false;

        created() {
            this.$root.$off('log-details-dialog-enable');
            this.$root.$on('log-details-dialog-enable', (data) => {
                this.openDialog(data.id);
            });
        }

        openDialog(id) {
            Request.make('/log/info/' + id, Request.GET).then((res) => {
                this.data = res;
                this.modal = true;
            });
        }
    }
</script>