const SupplierList = () => import("@/views/supplier/list.vue");
const SupplierView = () => import("@/views/supplier/view.vue");
const SupplierTabGeneral = () => import("@/views/supplier/tabs/general.vue");
const SupplierTabMemo = () => import("@/views/supplier/tabs/memo.vue");
const SupplierTabTitle = () => import("@/views/supplier/tabs/title.vue");

export default [
    { path: "/suppliers", name: "supplier_list", component: SupplierList, meta: { title: "Suppliers" }, props: true},
    {
        path: "/supplier",
        name: "supplier_create",
        component: SupplierView,
        meta: { title: "Add Supplier" },
        props: true,
        children: [
            { path: "general", name: "supplier_create_general", component: SupplierTabGeneral, props: false },
        ],
    },
    {
        path: "/supplier/view/:id",
        name: "supplier_view",
        component: SupplierView,
        meta: { title: "View Supplier" },
        props: true,
        children: [
            { path: "general", name: "supplier_update_general", component: SupplierTabGeneral, props: true },
            { path: "memo", name: "supplier_update_memo", component: SupplierTabMemo, props: true },
            { path: "title", name: "supplier_update_title", component: SupplierTabTitle, props: true },
        ],
    },
];
