const ContactView = () => import("@/views/contact/view.vue");

export default [
    {
        path: "/contact",
        component: ContactView,
        name: "contact_page",
        meta: { title: "Contact" },
        props: false,
    },
];
