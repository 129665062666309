const CustomerList = () => import("@/views/customer/list.vue");
const CustomerTabAddress = () => import("@/views/customer/tabs/address.vue");
const CustomerTabCommunication = () => import("@/views/customer/tabs/communication.vue");
const CustomerTabEmailPreferences = () => import("@/views/customer/tabs/email-preferences.vue");
const CustomerTabGeneral = () => import("@/views/customer/tabs/general.vue");
const CustomerTabInvoice = () => import("@/views/customer/tabs/invoice.vue");
const CustomerTabLog = () => import("@/views/customer/tabs/log.vue");
const CustomerTabMemo = () => import("@/views/customer/tabs/memo.vue");
const CustomerTabOrder = () => import("@/views/customer/tabs/order.vue");
const CustomerTabOrganization = () => import("@/views/customer/tabs/organization.vue");
const CustomerTabPeople = () => import("@/views/customer/tabs/people.vue");
const CustomerTabPostmaview = () => import("@/views/customer/tabs/postmaview.vue");
const CustomerTabPrice = () => import("@/views/customer/tabs/price.vue");
const CustomerTabAlerts = () => import("@/views/customer/tabs/alerts.vue");
const CustomerView = () => import("@/views/customer/view.vue");
const SettingsTabBulkCalc = () => import("@/views/settings/components/bulk-calc.vue");
const CustomerImport = () => import("@/views/customer/import.vue");

export default [
    { path: "/relaties", name: "customers", component: CustomerList, meta: { title: "Customers" } },
    { path: "/relaties-importeren", name: "customers_import", component: CustomerImport, meta: { title: "Customer import" } },
    {
        path: "/relatie",
        name: "relation_create",
        component: CustomerView,
        meta: { title: "Klant toevoegen" },
        props: false,
        children: [
            { path: "general", name: "relation_create_general", component: CustomerTabGeneral, props: false },
        ],
    },
    {
        path: "/relatie/:id",
        name: "relation_view",
        component: CustomerView,
        meta: { title: "Klant aanpassen" },
        props: true,
        children: [
            { path: "general", name: "relation_update_general", component: CustomerTabGeneral, props: true },
            { path: "people", name: "relation_update_people", component: CustomerTabPeople, props: true },
            { path: "address", name: "relation_update_address", component: CustomerTabAddress, props: true },
            { path: "communication", name: "relation_update_communication", component: CustomerTabCommunication, props: true },
            { path: "organization", name: "relation_update_organization", component: CustomerTabOrganization, props: true },
            { path: "postmaview", name: "relation_update_postmaview", component: CustomerTabPostmaview, props: true },
            { path: "invoice", name: "relation_update_invoice", component: CustomerTabInvoice, props: true },
            { path: "price", name: "relation_update_price", component: CustomerTabPrice, props: true },
            { path: "bulk_calc", name: "relation_update_bulk_calc", component: SettingsTabBulkCalc, props: true },
            { path: "orders", name: "relation_update_orders", component: CustomerTabOrder, props: true },
            { path: "logs", name: "relation_update_logs", component: CustomerTabLog, props: true },
            { path: "memos", name: "relation_update_memos", component: CustomerTabMemo, props: true },
            { path: "email-preferences", name: "relation_update_email_preferences", component: CustomerTabEmailPreferences, props: true },
            { path: "alerts", name: "relation_update_alerts", component: CustomerTabAlerts, props: true },
        ],
    },
];
