//import { Vue } from 'vue/types/vue';
import { Request } from '@/lib/apollo';

declare module 'vue/types/vue' {
    interface Vue {
        $getLocale(relationID?: string) : void;

    }
}

export default class OrganizationLayerPlugin {
    public static install(Vue, options) {
       Vue.prototype.$getLocale = function (relationID = null) {
           if (relationID) {
               Request.make('/locale/get/' + relationID).then((res) => {
                  return res;
               });
           } else {
               Request.make('/locale/get/').then((res) => {
                   return res;
               });
           }
        }
    }
}
