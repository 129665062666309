import Request from './request';
import ResourceCollection from './resourceCollection';

import _ from 'lodash';

export interface ApiResource {
    id: string;
    name: string;
    title: string;
    url: string;
    readableFields: any[];
    writableFields: any[];
}

interface SortOptions {
    key: string;
    direction: string;
}

export default class Resource {
    private _resource: ApiResource;

    constructor(resource: ApiResource) {
        this._resource = resource;
    }

    public get fields() {
        return this._resource.writableFields;
    }

    public request(url, method: string, data?: object): Promise<object> {
        return Request.make(url, method, data);
    }

    public get(id: string|number, ld: boolean = false): Promise<object> {
        const url = `${this._resource.url}/${id}`;
        return Request.make(url, Request.GET, null, ld);
    }

    public create(data: object, flatten: string[] = []): Promise<object> {
        const flattenedData = {};
        if (flatten.length) {
            _.forEach(data, (value, key) => {
                if (typeof value === 'object' && flatten.indexOf(key) !== -1 && ('@id' in value)) {
                    flattenedData[key] = value['@id'];
                    return;
                }

                flattenedData[key] = value;
            });
        }

        return Request.make(this._resource.url, Request.POST, flatten.length ? flattenedData : data, true);
    }

    public update(id: string|number, data: object, flatten: string[] = []): Promise<object> {
        if (typeof id === "number") {
            id = id.toString();
        }

        const url = id.indexOf('/') === 0 ? id : `${this._resource.url}/${id}`;

        const flattenedData = {};
        if (flatten.length) {
            _.forEach(data, (value, key) => {
                if (typeof value === 'object' && flatten.indexOf(key) !== -1 && ('@id' in value)) {
                    flattenedData[key] = value['@id'];
                    return;
                }

                flattenedData[key] = value;
            });
        }

        return Request.make(url, Request.PUT, flatten.length ? flattenedData : data, true);
    }

    public delete(id: string|number): Promise<object> {
        if (typeof id === "number") {
            id = id.toString();
        }

        const url = id.indexOf('/') === 0 ? id : `${this._resource.url}/${id}`;

        return Request.make(url, Request.DELETE);
    }

    public action(action: string, id?: number|string, method: string = Request.POST, data: any = {}) {
        let url = '';

        if (id) {
            url = `${this._resource.url}/${id}/${action}`;
        } else {
            url = `${this._resource.url}/${action}`;
        }

        return Request.make(url, method, data);
    }

    public search(params: object, sortOptions: SortOptions|SortOptions[] = []): Promise<any> {
        if (!(sortOptions instanceof Array)) {
            sortOptions = [sortOptions];
        }

        const orderParam = {};
        for (const sortObj of sortOptions) {
            orderParam[`_order[${sortObj.key}]`] = sortObj.direction;
        }

        _.extend(params, orderParam);

        return Request.make(this._resource.url, Request.GET, params, true).then((res: any) => {
            return new ResourceCollection(res);
        });
    }

    public all(filter?: object): Promise<any> {
        return Request.make(this._resource.url, Request.GET, filter, true).then((res: any) => {
            return new ResourceCollection(res);
        });
    }
}
