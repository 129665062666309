//import { Vue } from 'vue/types/vue';
import unescape from "lodash/fp/unescape";

declare module 'vue/types/vue' {
    interface Vue {
        $getCookie(key: string);

        $setCookie(key: string, value: string, domain?: string, maxAge?: number, expires?: Date, secure?: boolean);
    }
}

export default class CookiePlugin {
    public static install(Vue, options) {
        Vue.prototype.$getCookie = function (key: string) {
            key = key.replace(/([.*+?^=!:${}()|[\]\/\\])/g, '\\$1');

            const regex = new RegExp('(?:^|;)\\s?' + key + '=(.*?)(?:;|$)', 'i'),
                match = document.cookie.match(regex);

            return match && unescape(match[1]);
        };
        Vue.prototype.$setCookie = function (key: string, value: string, domain?: string, maxAge?: number, expires?: Date, secure?: boolean) {
            let cookie: string = key + '=' + encodeURIComponent(value);
            if (domain) {
                cookie += ';domain=' + encodeURIComponent(domain);
            }
            if (maxAge) {
                cookie += ';max-age=' + maxAge.toFixed(0);
            }
            if (expires) {
                cookie += ';expires=' + expires.toUTCString();
            }
            if (secure === true) {
                cookie += ';secure';
            }

            document.cookie = cookie;
        };
    }
}
