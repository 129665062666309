<template>
    <v-dialog v-model="offline" max-width="290">
        <v-card>
            <v-card-title>
                Offline
            </v-card-title>
            <v-card-text>
                {{ $t('Offline') }}
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script type="typescript" lang="ts">
    import { Component, Vue } from "vue-property-decorator";

    @Component
    export default class OfflineDialog extends Vue {
        offline: boolean = false;

        created() {
            window.addEventListener('online', () => {
                this.offline = false;
            });
            window.addEventListener('offline', () => {
                this.offline = true;
            });
        }
    }
</script>