//import { Vue } from "vue/types/vue";

declare module "vue/types/vue" {
    interface Vue {
        $dataText(loading: boolean);
    }
}

export default class DataTextPlugin {
    public static install(Vue, options) {
        Vue.prototype.$dataText = function(loading: boolean) {
            return loading ? this.$t("Loading results") : this.$t("No results found");
        };
    }
}
