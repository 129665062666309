const ManualList = () => import("@/views/manual/list.vue");
const ManualView = () => import("@/views/manual/view.vue");
const ManualTabGeneral = () => import("@/views/manual/tabs/general.vue");

export default [
    {
        path: "/manual/admin",
        name: "manual_admin",
        component: ManualList,
        meta: {title: "Manual"},
        props: true
    },
    {
        path: "/manual/view",
        name: "manual_create",
        component: ManualView,
        meta: {title: "Add Manual"},
        props: false,
        children: [
            {
                path: "general",
                name: "manual_entries_create_general",
                component: ManualTabGeneral,
                props: false
            },
        ],
    },
    {
        path: "/manual/edit/:id",
        name: "manual_edit",
        component: ManualView,
        meta: {title: "Edit Manual"},
        props: true,
        children: [
            {
                path: "general",
                name: "manual_entries_update_general",
                component: ManualTabGeneral,
                props: true
            },
        ],
    }
];
