<template>
    <div class="fill-height">
        <v-navigation-drawer class="fill-height sidebar" app light floating fixed persistent v-model="$store.state.drawer">
            <v-list>
                <v-list-item>
                    <v-list-item-title>
                        <router-link to="/"><img class="menu-logo" src="logo-dark.png" style="max-width: 100%" alt="" /></router-link>
                    </v-list-item-title>
                    <v-list-item-icon>
                        <v-btn icon @click.native.stop="$store.commit('toggleDrawer')">
                            <v-icon>fas fa-chevron-left</v-icon>
                        </v-btn>
                    </v-list-item-icon>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
        <v-app-bar app fixed flat>
            <v-app-bar-nav-icon v-if="!$store.state.drawer" light @click.native.stop="$store.commit('toggleDrawer')" />
            <template v-if="search.enabled">
                <v-text-field
                    :placeholder="search.placeholder"
                    prepend-inner-icon="fas fa-search"
                    class="full-width main-search"
                    v-model="search.value"
                    id="data-search-field"
                    autofocus
                    clearable
                    hide-details
                    solo
                    flat
                    outlined
                    dense
                />
            </template>
        </v-app-bar>
        <v-main>
            <v-container fluid style="padding-left: 20px; padding-right: 20px">
                <h1 class="headline mb-3">{{ routeTitle }}</h1>
                <v-container class="pa-0" fluid>
                    <slot />
                </v-container>
            </v-container>
        </v-main>
    </div>
</template>

<script lang="ts" type="typescript">
import { Component, Vue } from "vue-property-decorator";
import { SearchOptions } from "@/layouts/default.vue";

@Component({})
export default class PublicLayout extends Vue {
    searchTimeout;
    searchUnwatch;
    search: SearchOptions = {
        enabled: false,
        value: '',
        placeholder: 'Zoeken...',
        loading: false,
        onSearch: null,
        onEnter: null
    };

    logoFile: string = '/logo-dark.png';

    created() {
        this.$root.$off('enable-search');
        this.$root.$on('enable-search', (params: SearchOptions) => {
            params['enabled'] = true;
            Object.assign(this.search, params);

            this.searchUnwatch = this.$watch('search.value', this.onSearch);
        });

        this.$root.$off('disable-search');
        this.$root.$on('disable-search', () => {
            this.search = {
                enabled: false,
                value: '',
                placeholder: 'Zoeken...',
                loading: false,
                onSearch: null,
                onEnter: null,
            };

            if (typeof this.searchUnwatch === 'function') {
                this.searchUnwatch();
            }
        });

        this.$root.$on('set-logo', (file) => {
            this.logoFile = file;
        });
    }

    get routeTitle(): string {
        let title = this.$route.params['metaTitle'] || this.$route.meta.title;
        if (!this.$route.meta.disableTranslate) {
            title = this.$t(title);
        }
        return title;
    }

    onSearch(val) {
        window.clearTimeout(this.searchTimeout);
        this.searchTimeout = window.setTimeout(() => {
            if (typeof this.search.onSearch === 'function') {
                this.search.onSearch(val);
            }
        }, 400);
    }
}
</script>
