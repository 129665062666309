import Request from "@/lib/apollo/request";
import store from "@/store";
import router from "@/router";
import flashMessage from "@/flashMessage";

export default function checkPasswordResetOnLogin(fromError: boolean = false): void {
    Request.make('/user/is-forced')
        .catch(e => flashMessage('Error', 'error'))
        .then((res: { forced: boolean, note: string }) => {
            if (!res.forced) {
                store.commit('passwordResetOnLogin', false);
                router.push('/');
            } else {
                store.commit('passwordResetOnLogin', res.forced);

                if (router.currentRoute.name !== 'change-password-login') {
                    router.push({ name: 'change-password-login' });
                } else {
                    if (fromError) {
                        flashMessage('Error', 'error');
                    }
                }
            }
        });
}
