const OrderList = () => import("@/views/order/list-order.vue");
const SubscriptionList = () => import("@/views/order/list-subscription.vue");
const CancellationList = () => import("@/views/order/list-cancellation.vue");
const ManualRenewList = () => import("@/views/order/list-manual-renew.vue");
const TotalByProductList = () => import("@/views/order/list-product.vue");
const MembershipList = () => import("@/views/order/list-membership.vue");
const TotalList = () => import("@/views/order/list-total.vue");
const TotalForeignList = () => import("@/views/order/list-total-foreign.vue");
const PendingList = () => import("@/views/order/list-pending.vue");
const OrderView = () => import("@/views/order/view.vue");
const OrderTabGeneral = () => import("@/views/order/tabs/general.vue");
const OrderTabAddress = () => import("@/views/order/tabs/address.vue");
const OrderTabReceiptRegistration = () => import("@/views/order/tabs/receiptregistration.vue");
const OrderTabSubscriptionReceiptRegistration = () => import("@/views/order/tabs/subscription_receiptregistration.vue");
const OrderTabCirculation = () => import("@/views/order/tabs/circulation.vue");
const OrderTabInvoiceInfo = () => import("@/views/order/tabs/invoice-info.vue");
const OrderTabContact = () => import("@/views/order/tabs/contact.vue");
const OrderTabInvoice = () => import('@/views/order/tabs/invoices.vue');
const OrderTabMigrate = () => import('@/views/order/tabs/migrate.vue');
const OrderTabMail = () => import('@/views/order/tabs/mail.vue');
const OrderTabMailsOut = () => import('@/views/order/tabs/mails-out.vue');
const OrderTabDigicom = () => import('@/views/order/tabs/digicom.vue');

let viewChildren = (prefix) => {
    return [
        {
            path: "general",
            name: prefix + "_view_general",
            component: OrderTabGeneral,
            meta: { title: "View order" },
            props: true
        },
        {
            path: "address",
            name: prefix + "_view_address",
            component: OrderTabAddress,
            meta: { title: "View order" },
            props: true
        },
        {
            path: "receiptregistration",
            name: prefix + "_view_receiptregistration",
            component: prefix === 'subscription' ? OrderTabSubscriptionReceiptRegistration : OrderTabReceiptRegistration,
            meta: { title: "View order" },
            props: true
        },
        {
            path: "invoice_details",
            name: prefix + "_view_invoice_details",
            component: OrderTabInvoiceInfo,
            meta: { title: "View order" },
            props: true
        },
        {
            path: "circulation",
            name: prefix + "_view_circulation",
            component: OrderTabCirculation,
            meta: { title: "View order" },
            props: true
        },
        {
            path: "invoice",
            name: prefix + "_view_invoice",
            component: OrderTabInvoice,
            meta: { title: "View order" },
            props: true
        },
        {
            path: "migrate",
            name: prefix + "_view_migrate",
            component: OrderTabMigrate,
            meta: { title: "View order" },
            props: true
        },
        {
            path: "contact",
            name: prefix + "_view_contact",
            component: OrderTabContact,
            meta: { title: "View order" },
            props: true
        },
        {
            path: "mail",
            name: prefix + "_view_mail",
            component: OrderTabMail,
            meta: { title: "View order" },
            props: true
        },
        {
            path: "mails_out",
            name: prefix + "_view_mails_out",
            component: OrderTabMailsOut,
            meta: { title: "View order" },
            props: true
        },
        {
            path: "digicom",
            name: prefix + "_view_digicom",
            component: OrderTabDigicom,
            meta: { title: "View order" },
            props: true
        }
    ];
};

export default [
    { path: "/orders", name: "order_list", component: OrderList, meta: { title: "Orders" }, props: false },
    { path: "/subscriptions", name: "subscription_list", component: SubscriptionList, meta: { title: "Subscriptions" }, props: false },
    { path: "/memberships", name: "membership_list", component: MembershipList, meta: { title: "Memberships" }, props: false },
    { path: "/total-overview", name: "total_overview_list", component: TotalList, meta: { title: "All orders" }, props: false },
    { path: "/total-foreign", name: "total_foreign_list", component: TotalForeignList, meta: { title: "Foreign orders" }, props: false },
    { path: "/cancellations", name: "cancellation_list", component: CancellationList, meta: { title: "Cancellation" }, props: false },
    { path: "/manual-renew", name: "manual_renew_list", component: ManualRenewList, meta: { title: "Manually renew" }, props: false },
    { path: "/previously-ordered/:productId", name: "previously_ordered_list", component: TotalByProductList, meta: { title: "Previous orders with product" }, props: true },

    { path: "/pending-orders", name: "pending_order_list", component: PendingList, meta: { title: "New orders" }, props: false },

    { path: "/open-overview", name: "order_list_open", component: OrderList, meta: { title: "Open orders" }, props: false },
    { path: "/orders/status", name: "order_list_pending", component: OrderList, meta: { title: "History" }, props: false },

    {
        path: "/orders/view/:id",
        name: "order_view",
        component: OrderView,
        meta: { title: "View order" },
        props: true,
        children: viewChildren('order'),
    },
    {
        path: "/subscriptions/view/:id",
        name: "subscription_view",
        component: OrderView,
        meta: { title: "Bekijk Abonnement" },
        props: true,
        children: viewChildren('subscription'),
    },
    {
        path: "/total-overview/view/:id",
        name: "total_overview_view",
        component: OrderView,
        meta: { title: "Bekijk Order of Abonnement" },
        props: true,
        children: viewChildren('total'),
    },

];
