const CirculationTabLists = () => import("@/views/circulation/tabs/list.vue");
const CirculationTabSettings = () => import("@/views/circulation/tabs/settings.vue");
const CirculationView = () => import("@/views/circulation/view.vue");
const CirculationAdmin = () => import("@/views/circulation/admin.vue");

export default [
    {
        path: "/circulation",
        component: CirculationView,
        meta: { title: "Circulation lists" },
        props: false,
        name: "circulation",
        children: [
            {path: "list", component: CirculationTabLists, props: false, name: "circulation_list", meta: { title: "Circulation lists" }},
            {path: "settings", component: CirculationTabSettings, props: false, name: "circulation_settings"},
        ]
    },
    {
        path: "/admin/circulation",
        component: CirculationAdmin,
        meta: { title: "Circulation lists" },
        name: "admin-circulation"
    }
];
