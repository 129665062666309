<template>
    <v-card class="full-width">
        <v-card-title>
            <template v-if="progress.total > 0">
                <!-- Geen translation maar dit onderdeel is Wietse only dus... -->
                Er worden op dit moment facturen verwerkt en verzonden.
                {{ progress.processed }} / {{ progress.total }}
                <v-progress-linear v-model="progressBarValue"></v-progress-linear>
            </template>
        </v-card-title>
        <data-table
                :no-data-text="$dataText(loading)"
                :loading="loading"
                :headers="headers"
                v-model="queried"
                :items="items"
                item-key="id"
                :sort-by="[]"
                v-bind:options.sync="pagination"
                show-select
        >
            <template v-slot:item.createdAt="{ item }">
                <td class="text-left">{{ item.date | moment(dateformat) }}</td>
            </template>
            <template v-slot:item.number="{ item }">
                <td class="text-left">{{ item.number }}</td>
            </template>
            <template v-slot:item.relation.name="{ item }">
                <td class="text-left">{{ item.relation.name }}</td>
            </template>
            <template v-slot:item.relation.invoiceEmail="{ item }">
                <td class="text-left">{{ item.relation.invoiceEmail }}</td>
            </template>
            <template v-slot:item.actions="{ item }">
                <td class="text-right">
                    <v-btn color="primary" @click.stop="openPdf(item.id)">
                        <v-icon action white>far fa-file-pdf</v-icon>
                    </v-btn>
                </td>
            </template>
        </data-table>
        <v-card-actions>
            <v-btn text color="primary" @click="sendSelectedInvoices" :disabled="sending">
                <template v-if="remaining > 0">
                    {{ $t('{0} remaining').replace('{0}', remaining) }}
                </template>
                <template v-else>
                    {{ $t('Send invoices') }}
                </template>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="clear" text color="red" v-if="!sending" >
                {{ $t('Clear') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script lang="ts" type="typescript">
    import { Request } from '@/lib/apollo';
    import Vue from "vue";
    import { Component } from "vue-property-decorator";
    import Config from "@/config";

    interface InvoiceObject {
        id: string;
        relation: {
            name: string,
            invoiceEmail: string,
        };
        number: number;
        date: string;
        queuedForSending: any;
        sentAt: any;
        errorWhileSending: boolean;
    }

    @Component
    export default class PrintQueueList extends Vue {
        loading: boolean = true;
        headers = [
            { text: '', value: 'checkbox' },
            { text: 'Date', value: 'createdAt' },
            { text: 'Invoice Number', value: 'number' },
            { text: 'Customer', value: 'relation.name' },
            { text: 'Invoice e-mail', value: 'relation.invoiceEmail' },
            { text: '', value: 'actions' }
        ];
        items: InvoiceObject[] = [];
        queried = [];
        dateformat = Config.dateformat;
        sending: boolean = false;
        remaining = 0;

        pagination: any = {
            sortDesc: [false],
            sortBy: ['createdAt'],
            page: 1,
            itemsPerPage: 10,
        };

        progress = {
            processed: 0,
            queued: 0,
            total: 0
        };

        progressInterval: number = 0;

        get selected() {
            return Object.keys(this.queried).length !== 0
        }

        get progressBarValue() {
            const fraction = this.progress.total / this.progress.processed;

            return 100 / fraction;
        }

        created() {
            this.getInvoices();
        }

        getInvoices() {
            this.loading = true;
            Request.make('/api/printqueue/ready', Request.GET).then((res) => {
               this.items = res.invoices;
               this.sending = res.running;
               this.remaining = res.runningCount;
               this.queried = [];
               this.loading = false;
            });
        }

        getRunning() {
            Request.make('/api/printqueue/running', Request.GET).then((res) => {
                this.sending = res.running;
                this.remaining = res.runningCount;
                if (!res.running) {
                    window.clearInterval(this.progressInterval);
                }
            });
        }

        clear() {
            if (confirm('Weet u het zeker?')) {
                Request.make('/api/printqueue', Request.DELETE).then(() => {
                    this.getInvoices();
                    this.$flashMessage('Printqueue geleegd!');
                });
            }
        }

        openPdf(id) {
            let link = Config.api.host + '/api/invoices/' + id + '/render';
            window.open(link, '_blank');
        }

        sendSelectedInvoices() {
            console.log(this.queried);
            const ids = this.queried.map((obj) => obj.id);
            if (!ids.length) {
                this.$flashMessage('Niks geselecteerd!', 'error')
            }

            //console.log(ids);
            //return;

            this.sending = true;
            Request.make('/api/printqueue/send', Request.POST, {ids})
                .then((res) => {
                    this.$flashMessage('Verzonden!', 'success');

                    this.getInvoices();
                    this.sending = false;
                    /*this.$root.$emit('display-task-progress', {
                        'id': res.jobID,
                        'callback': () => {
                            this.getInvoices();
                            this.sending = false;
                        },
                    });*/

                    this.progressInterval = window.setInterval(this.getRunning, 2000);
                }).catch(() => {
                    this.sending = false;
                    this.$flashMessage('Er is een fout opgetreden!', 'error');
                });
        }
    }
</script>
