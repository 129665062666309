//import { Vue } from 'vue/types/vue';
import Apollo, { Request } from "@/lib/apollo";

declare module 'vue/types/vue' {
    interface Vue {
        $addToCart(product: {}): void;
        $createOrderInCart(product: {}): void;
        $setQuantityInCart(id, quantity): void;

        $removeFromCart(id: string, flash: boolean): void;

        $clearCart(): Promise<any>;
    }
}

export default class ShoppingCartPlugin {
    public static install(Vue, options) {
        Vue.prototype.$addToCart = function (product: {}) {
            this.$root.$emit('add-to-cart', product);
        };

        Vue.prototype.$createOrderInCart = function (product: {}) {
            Apollo.get('cart').create({product: '/api/products/' + encodeURIComponent(product['id'])}).then(cartItem => {
                this.$flashMessage('Product added to cart', 'success');
                this.$store.commit('addToCart', cartItem);
            });
        };

        Vue.prototype.$setQuantityInCart = function (id, quantity) {
            Request.make('/api/cart/set-quantity/' + encodeURIComponent(id) + '/' + encodeURIComponent(quantity)).then((result) => {
                if (result.status && result.status === 'OK') {
                    this.$flashMessage('Product added to cart', 'success');
                }
            });
        };

        Vue.prototype.$removeFromCart = function (id, flash: boolean = true) {
            if (typeof flash === 'undefined') {
                flash = true;
            } else {
                flash = !!flash;
            }
            Apollo.get('cart').delete(id).then(res => {
                if (flash === true) {
                    this.$flashMessage('Product removed from cart', 'success');
                }
                this.$store.commit('removeFromCart', id);
            });
        };

        Vue.prototype.$clearCart = function () {
            return new Promise((resolve, reject) => {
                Apollo.get('cart').delete('_all')
                    .then(res => {
                        this.$flashMessage('Shopping cart cleared', 'success');
                        this.$store.commit('clearCart');
                        // this.$router.go();
                        resolve('success');
                    })
                    .catch(e => {
                        reject(e);
                    });
            })

        };
    }
}
