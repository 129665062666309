import queryString from 'query-string';
import Config from '@/config';
import router from '@/router';
import UploadProgress from './uploadProgress';
import checkPasswordResetOnLogin from "@/reset-password-on-login";

export default class Request {
    public static readonly GET = 'GET';
    public static readonly POST = 'POST';
    public static readonly PUT = 'PUT';
    public static readonly DELETE = 'DELETE';

    public static make(url, method: string = Request.GET, data: any = {}, ld: boolean = false): Promise<any> {
        let opts: any = {
            method: method,
            headers: {},
            credentials: 'include',
            redirect: 'follow'
        };

        url = this.parseUrl(url);

        if (ld === false) {
            opts.headers['accept'] = 'application/json';
            opts.headers['X-Requested-With'] = 'XMLHttpRequest';
        }

        if (method === Request.GET && data && typeof data === 'object' && Object.keys(data).length > 0) {
            url += '?' + queryString.stringify(data, { arrayFormat: 'bracket', sort: false });
        }

        if (method === Request.PUT || method === Request.POST) {
            if (typeof data === "undefined") {
                throw new Error('PUT/POST requests require a non-empty data parameter.');
            }

            if (data instanceof FormData) {
                opts.body = data;
            } else {
                opts.body = JSON.stringify(data);
                opts.headers['Content-Type'] = ld ? 'application/ld+json' : 'application/json';
            }
        }

        return this.send(url, opts);
    }

    public static makeXmlRequest(url, method = Request.POST, data) {
        const opts: RequestInit = {
            method,
            headers: {},
            credentials: 'include',
            redirect: 'follow',
            cache: 'no-store',
            mode: 'no-cors',
        };

        url = this.parseUrl(url);

        if (method === Request.PUT || method === Request.POST) {
            if (typeof data === "undefined") {
                throw new Error('PUT/POST requests require a non-empty data parameter.');
            }

            opts.body = data;
            opts.headers['Content-Type'] = 'application/xml';
        }

        return fetch(url, opts).then((res) => {
            if (res.status === 401 && !(url.match(/\/login/)) && !(url.match(/\/2fa_check/))) {
                router.push({ name: 'login' });
                return;
            }

            const contentType = res.headers.get('Content-Type');

            if (res.ok === false) {
                if (contentType.includes('application/json') || contentType.includes('application/ld+json')) {
                    return res.json().then(Promise.reject.bind(Promise));
                }

                if (res.status == 403) {
                    checkPasswordResetOnLogin(true);
                }

                return res.text().then(() => {
                    Promise.reject.bind(Promise);
                });
            }

            if (opts.method === Request.DELETE) {
                return {};
            }

            return res;
        });
    }

    public static send(url, opts) {
        return fetch(url, opts).then((res) => {
            if (res.status === 401 && !url.match(/\/login/) && !url.match(/\/2fa_check/)) {
                router.push({ name: 'login' });
                return;
            }

            const contentType = res.headers.get('Content-Type');

            if (res.ok === false) {
                if (contentType.includes('application/json') || contentType.includes('application/ld+json')) {
                    return res.json().then(Promise.reject.bind(Promise));
                }

                if (res.status == 403) {
                    checkPasswordResetOnLogin(true);
                }

                return res.text().then(Promise.reject.bind(Promise));
            }

            if (opts.method === Request.DELETE) {
                return {};
            }

            if (contentType.includes('application/json') || contentType.includes('application/ld+json')) {
                return res.json();
            }

            return res.text();
        });
    }

    public static upload(url: string, file: File, progress?: UploadProgress): Promise<any> {
        url = this.parseUrl(url);

        let formData = new FormData();
        formData.append('logo', file); // uhh..

        let handleProgress: boolean = !!progress;

        return new Promise<any>((resolve, reject) => {
            let xhr = new XMLHttpRequest();

            if (xhr.upload) {
                let formData = new FormData();
                formData.append('file', file);

                let getResponse = () => {
                    const contentType = xhr.getResponseHeader('Content-Type');

                    if (contentType.indexOf('application/json') === 0 || contentType.indexOf('application/ld+json') === 0) {
                        return JSON.parse(xhr.response);
                    }
                    return xhr.responseText;
                };

                if (handleProgress) {
                    xhr.onprogress = (e) => {
                        let value: number = 100 - (e.loaded / e.total * 100);
                        progress.progress = Math.round(value);
                    };
                }

                xhr.onload = () => {
                    resolve(getResponse());

                    if (handleProgress) {
                        progress.inProgress = false;
                        progress.progress = 0;
                    }
                };

                xhr.onerror = () => {
                    reject(getResponse());
                };

                xhr.open('POST', url, true);
                xhr.withCredentials = true;
                xhr.setRequestHeader('Accept', 'application/json');
                xhr.send(formData);

                if (handleProgress) {
                    progress.inProgress = true;
                }
            }
        });
    }

    private static parseUrl(url: string) {
        let isAbsolute: boolean = url.indexOf('http') === 0;
        let isApiRequest: boolean = url.indexOf(Config.api.host) === 0;
        if (!isAbsolute || (!isApiRequest && !isAbsolute)) {
            let host = Config.api.host;
            host = host.replace(/\/$/, '');
            url = url.replace(/^\//, '');
            url = host + '/' + url;
        }
        return url;
    }
}
