import NewsManage from './manage.vue';
import NewsOverview from './overview.vue';

export default [
    {path: "/news/create", name: "news_create", component: NewsManage, meta: {title: 'News item'}},
    {path: "/news/edit/:id", name: "news_edit", component: NewsManage, meta: {title: 'News item'}, props: true},
    {
        path: "/news/overview",
        name: "news_overview",
        component: NewsOverview,
        meta: { title: 'News Messages' },
        props: true
    },
];