//import { Vue } from 'vue/types/vue';

interface VueCacheOptions {
    refreshInterval?: number;
    lazy?: boolean;
}

declare module 'vue/types/vue' {
    interface Vue {
        $cacheGet(key: string);
        $cacheSet(key: string, value: any, options?: VueCacheOptions);
        $cacheHas(key: string): boolean;
        $cacheRefresh(key: string);
        $getSearchQueryForRoute();
    }
}

export default class CachePlugin {
    public static install(Vue, options) {
        Vue.prototype.$cacheGet = async function(key: string) {
            let cacheItem = this.$store.state.cache.get(key);

            if ((typeof cacheItem === 'undefined' || cacheItem === null) && this.$store.state.cacheCallbacks.has(key)) {
                let callback: 'function' = this.$store.state.cacheCallbacks.get(key);
                if (typeof callback === 'function') {
                    cacheItem = await this.$store.state.cacheCallbacks.get(key)();

                    this.$cacheSet(key, cacheItem);
                }
            }

            if (typeof cacheItem === 'undefined') {
                return [];
            }

            return cacheItem;
        };

        Vue.prototype.$cacheSet = async function(key: string, value: any, options?: VueCacheOptions) {
            options = Object.assign({ refreshInterval: null, lazy: false }, options);
            if (typeof value === 'function') {
                const callback = value;
                value = options.lazy ? null : await callback();

                if (options.refreshInterval !== null) {
                    setInterval(() => {
                        this.$cacheRefresh(key);
                    }, options.refreshInterval);
                }

                this.$store.commit('cache', { key, value, callback });
                return;
            }

            this.$store.commit('cache', { key, value });
        };

        Vue.prototype.$cacheHas = function(key: string) {
            const cacheItem = this.$store.state.cache.get(key);

            return typeof cacheItem !== 'undefined' || cacheItem === null;
        };

        Vue.prototype.$cacheRefresh = function(key: string) {
            if (!this.$store.state.cacheCallbacks.has(key)) {
                return null;
            }

            const cacheItem = this.$store.state.cacheCallbacks.get(key)();
            this.$cacheSet(key, cacheItem);

            return cacheItem;
        };

        Vue.prototype.$getSearchQueryForRoute = function() {
            if (this.$store.state.searchQueryForRoute.hasOwnProperty(this.$route.name)) {
                return this.$store.state.searchQueryForRoute[this.$route.name];
            }
            return '';
        };
    }
}
