<template>
    <main v-if="$store.state.initialized" style="background: url('login.jpg');height:100%">
        <v-container fluid>
            <v-snackbar
                    :timeout="6000"
                    v-bind="$store.state.flashMessageOptions"
                    :top="true"
                    v-model="$store.getters.hasFlash"
            >
                {{ $store.state.flashMessage }}
                <v-btn light text @click.native="$store.commit('flashMessage', '')">Sluiten</v-btn>
            </v-snackbar>
            <slot />
        </v-container>
        <offline-dialog />
    </main>
</template>

<script lang="ts" type="typescript">
import { Component, Vue } from 'vue-property-decorator';
import OfflineDialog from "@/components/offline.vue";

@Component({
    components: {
        OfflineDialog
    }
})
export default class LoginLayout extends Vue {
    mounted() {
        let launcher = document.getElementById('launcher');
        if (launcher) {
            launcher.style.display = '';
        }
    }
}
</script>
