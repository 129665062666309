const ProductView = () => import("@/views/product/create.vue");
const ProductTabGeneral = () => import("@/views/product/tabs/general.vue");
const ProductTabMemo = () => import("@/views/product/tabs/memo.vue");
const ProductTabAttachments = () => import("@/views/product/tabs/attachments.vue");
const ProductTabOrders = () => import("@/views/product/tabs/orders.vue");
const ProductTabEmail = () => import("@/views/product/tabs/email.vue");
const ProductTabPublicationSchedule = () => import("@/views/product/tabs/publication_schedule.vue");
const ProductNewOrder = () => import("@/views/product/neworder.vue");
const ProductRequests = () => import("@/views/product/requests.vue");

export default [
    {
        path: "/product/requests",
        name: "product_requests",
        component: ProductRequests,
        meta: { title: "Product requests" },
        props: true,
    },
    {
        path: "/product/new-subscription",
        name: "product_new_order",
        component: ProductNewOrder,
        meta: { title: "New subscription" },
        props: true,
    },
    {
        path: "/product/create",
        name: "product_create",
        component: ProductView,
        meta: { title: "Create Product" },
        props: true,
        children: [
            {
                path: "general",
                name: "product_create_general",
                component: ProductTabGeneral,
                props: true,
                meta: { title: "Create Product" },
            },
        ],
    },
    {
        path: "/product/:id",
        name: "product_edit",
        component: ProductView,
        meta: { title: "Edit Product" },
        props: true,
        children: [
            {
                path: "general",
                name: "product_update_general",
                component: ProductTabGeneral,
                meta: { title: "View product" },
                props: true,
            },
            {
                path: "subscriptions",
                name: "product_update_orders",
                component: ProductTabOrders,
                meta: { title: "View product" },
                props: true,
            },
            {
                path: "memo",
                name: "product_update_memo",
                component: ProductTabMemo,
                meta: { title: "View product" },
                props: true,
            },
            {
                path: "attachments",
                name: "product_update_attachments",
                component: ProductTabAttachments,
                meta: { title: "View product" },
                props: true,
            },
            {
                path: "email",
                name: "product_update_email",
                component: ProductTabEmail,
                meta: { title: "View product" },
                props: true,
            },
            {
                path: "publication_schedule",
                name: "product_update_publication_schedule",
                component: ProductTabPublicationSchedule,
                meta: { title: "View product" },
                props: true,
            },
        ],
    },
];
