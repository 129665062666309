<template>
    <v-card class="full-width">
        <v-card-title>
            <h3 class="title" v-if="typeof id !== 'undefined' && id.length">{{ $t('Edit News Item') }}</h3>
            <h3 class="title" v-else>{{ $t('Create News Item') }}</h3>
        </v-card-title>
        <v-divider />
        <v-card-text>
            <v-row>
                <v-col cols="12">
                    <v-text-field :label="$t('Title')" v-model="formData.title" required />
                </v-col>
                <v-col cols="12">
                    {{ $t('Content') }}
                    <vue-editor v-model="formData.content" :editorToolbar="customToolbar" required />
                </v-col>
                <v-col cols="6" class="pr-2">
                    <date-picker label="From" v-model="formData.startDate" today required />
                </v-col>
                <v-col cols="6" class="pl-2">
                    <date-picker label="To" v-model="formData.endDate" />
                </v-col>
            </v-row>
        </v-card-text>
        <v-card-actions>
            <v-btn color="primary" @click="submit" :loading="saving">
                <v-icon left>far fa-save</v-icon>
                {{ $t('Save') }}
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script lang="ts" type="typescript">
    import { Component, Prop, Vue } from 'vue-property-decorator';
    import Apollo from '@/lib/apollo';
    import { VueEditor } from 'vue2-editor/dist/vue2-editor.core.js';
    import omit from "lodash/omit"

    interface FormData {
        startDate: string;
        endDate?: string;
        content: string;
        viewCount: number;
        title: string;
    }

    @Component({
        components: {
            VueEditor,
        },
    })
    export default class NewsManage extends Vue {
        @Prop()
        id: string;

        init: boolean = false;
        saving: boolean = false;

        formData = <FormData> {
            startDate: '',
            endDate: '',
            content: '',
            viewCount: 0,
            title: '',
        };

        customToolbar = [
            ['bold', 'italic', 'underline'],
            [{'list': 'ordered'}, {'list': 'bullet'}],
            ['image', 'code-block', 'insertPlaceHolder']
        ];

        submit() {
            if (this.init || !this.id) {
                this.saving = true;
                let request;

                if (!this.formData.endDate) {
                    this.formData = omit(this.formData, ['endDate']);
                }
                if (this.id) {
                    request = Apollo.get('news_items').update(this.id, this.formData)
                } else {
                    request = Apollo.get('news_items').create(this.formData)
                }

                request.then(res => {
                    this.saving = false;
                    this.$router.push({name: 'news_overview'})
                });

            }
        }

        created() {
            if (this.id) {
                Apollo.get('news_items').get(this.id).then((res: FormData) => {
                    this.formData = res;
                    this.init = true;
                });
            }
        }
    }
</script>

<style lang="css">
@import "~vue2-editor/dist/vue2-editor.css";
@import "~quill/dist/quill.core.css";
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

.ql-insertPlaceHolder::after {
    content: '⇧';
}
</style>