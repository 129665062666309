import Vue from "vue";

//imports
//import FaIcon from "@/components/fa-icon.vue";
import DataTable from "@/components/data-table.vue";
import DatePicker from "@/components/date-picker.vue";
import PocoSelect from "@/components/poco-select.vue";

//Vue.component('fa-icon', FaIcon);
Vue.component('data-table', DataTable);
Vue.component('date-picker', DatePicker);
Vue.component('poco-select', PocoSelect);
