const UserList = () => import("@/views/user/list.vue");
const UserView = () => import("@/views/user/view.vue");
const UserTabGeneral = () => import("@/views/user/tabs/general.vue");
const UserTabPermissions = () => import("@/views/user/tabs/permissions.vue");

export default [
    { path: "/gebruikers", name: "user_list", component: UserList, meta: { title: "Users" }, props: true },
    {
        path: "/gebruiker",
        name: "user_create",
        component: UserView,
        meta: { title: "Gebruiker toevoegen" },
        props: false,
        children: [
            { path: "general", name: "user_create_general", component: UserTabGeneral, props: false },
        ],
    },
    {
        path: "/gebruiker/:id",
        name: "user_edit",
        component: UserView,
        meta: { title: "Gebruiker aanpassen" },
        props: true,
        children: [
            { path: "general", name: "user_update_general", component: UserTabGeneral, props: true },
            { path: "permissions", name: "user_update_permissions", component: UserTabPermissions, props: true },
        ],
    },
];
