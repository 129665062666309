const ManagementInfoWrapper = () => import('./invoice/index.vue');
const FinancialOverviewOrderList = () => import("./order/list.vue");

const InvoiceBulkEdit = () => import("./invoice/bulk-edit.vue");
const InvoiceView = () => import("./invoice/view.vue");
const InvoiceOverview = () => import("./invoice/overview.vue");
const InvoiceList = () => import("./invoice/list_new.vue");
const InvoiceIntro = () => import("./invoice/introduction.vue");
const InvoiceImport = () => import("./import/form.vue");

const BudgetView = () => import("./budget/view.vue");
const BudgetOverview = () => import("./budget/tabs/overview.vue");
const BudgetSettings = () => import("./budget/tabs/setting.vue");

export default [
    {
        path: "/financial/overview",
        name: "financials_overview",
        meta: { title: "Invoice overview" },
        component: InvoiceOverview,
        props: true,
    },
    {
        path: "/financial/invoice-drafts",
        name: "financials_form",
        meta: { title: "Imported rows" },
        component: InvoiceList,
        props: false,
    },
    { path: '/financial/budgeting', name: 'financial_budgeting', component: BudgetView, props: false, children: [
            { path: "overview", name: "financial_budgeting_overview", component: BudgetOverview, props: false },
            { path: "settings", name: "financial_budgeting_settings", component: BudgetSettings, props: false },
        ]
    },
    { path: "/invoices/bulk-edit", name: "invoices_bulk_edit", meta: { title: "Change invoice dates" }, component: InvoiceBulkEdit },
    // Klanten
    { path: "/financial", name: 'financial', component: ManagementInfoWrapper, props: false, children: [
        {
            path: "intro",
            name: "financial_intro",
            meta: { title: 'Financial Introduction' },
            component: InvoiceIntro,
            props: false
        },
        {
            path: "overview1",
            name: "financial_overview1",
            meta: { title: 'Financial Overview I' },
            component: InvoiceView,
            props: false
        },
        {
            path: "overview2",
            name: "financial_overview2",
            meta: { title: 'Financial Overview II' },
            component: FinancialOverviewOrderList,
            props: { type: 'total', key: 'financialoverview2' }
        }
    ]},

    { path: '/invoice/import', name: 'invoice_import', component: InvoiceImport, props: false },
];
