const OrderBulkTool = () => import("@/views/bulk/tabs/order-bulk-tool.vue");
const ProductBulkTool = () => import("@/views/bulk/tabs/product-bulk-tool.vue");
const AddressBulkTool = () => import("@/views/bulk/tabs/address-bulk-tool.vue");
const ReceiptRegistrationBulkTool = () => import("@/views/bulk/tabs/receipt-registration-bulk-tool.vue");
const ExportLBBulkTool = () => import("@/views/bulk/tabs/export-lb-bulk-tool.vue");
const BulkToolView = () => import("@/views/bulk/view.vue");
const InvoiceReferenceBulkTool = () => import("@/views/bulk/tabs/invoice-reference-bulk-tool.vue");
const OrderRemoveBulkTool = () => import("@/views/bulk/tabs/order-remove-bulk-tool.vue");

export default [
    {
        path: "/bulk-tools",
        name: "bulk_tool",
        component: BulkToolView,
        meta: { title: "Bulk tools" },
        props: true,
        children: [
            {
                path: "orders",
                name: "bulk_tool_orders",
                component: OrderBulkTool,
                meta: { title: "Order Bulk" },
                props: true,
            },
            {
                path: "products",
                name: "bulk_tool_products",
                component: ProductBulkTool,
                meta: { title: "Product Bulk" },
                props: true,
            },
            {
                path: "addresses",
                name: "bulk_tool_addresses",
                component: AddressBulkTool,
                meta: { title: "Addresses Bulk" },
                props: true,
            },
            {
                path: "receipt-registrations",
                name: "bulk_tool_receipt_registrations",
                component: ReceiptRegistrationBulkTool,
                meta: { title: "Receipt registrations" },
                props: true,
            },
            {
                path: "export-lb",
                name: "bulk_tool_export_lb",
                component: ExportLBBulkTool,
                meta: { title: "Export LB" },
                props: true,
            },
            {
                path: "invoice-reference",
                name: "bulk_tool_invoice_reference",
                component: InvoiceReferenceBulkTool,
                meta: { title: "Factuurregel Referentie" },
                props: true,
            },
            {
                path: "order-remove",
                name: "bulk_tool_order_remove",
                component: OrderRemoveBulkTool,
                meta: { title: "Orders to remove" },
                props: true,
            }
        ],
    },
];
