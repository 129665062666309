const MessageThreadView = () => import("@/views/chat/message-thread.vue");

export default [
    {
        path: "/message-thread/view/:id",
        component: MessageThreadView,
        name: "view_message_thread",
        meta: { title: "View message" },
        props: true,
    },
];
