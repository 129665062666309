import iin from './i18n'

export interface Rules {
    required(value): boolean | string;

    email(value): boolean | string;

    number(value): boolean | string;
}

export default {
    required: (value) => {
        // uitzondering op het getal 0
        if (value === 0) {
            return String(value);
        } else {
            return !!value || iin.t('Required')
        }
    },
    email: (value, test) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || iin.t('Invalid') + ' e-mail.'
    },
    number: (value) => {
        let valid = true;
        if (typeof value === 'number') {
            valid = !isNaN(value);
        } else if (typeof value === 'string') {
            valid = !!value.match(/^[0-9]+$/);
        }

        return valid ? true : 'Must be a number';
    }
} as Rules