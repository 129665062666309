const PublicOverview = () => import("@/views/public/overview.vue");
import PublicLayout from "@/layouts/public.vue";

export default [
    {
        path: "/public/:id",
        name: "public_overview",
        component: PublicOverview,
        meta: { title: "Subscriptions", layout: PublicLayout },
        props: true,
    },
];
